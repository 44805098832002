import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (
        <>
            <div className='flex flex-wrap items-center md:justify-evenly mx-auto p-4 border-b-4 border-b-lime-400 z-50 sticky top-0 backdrop-blur-lg bg-white/80'>
                <div className='flex items-center justify-evenly'>
                    <div>
                        <Link to="/">
                            <img alt='arl-holidays-brand-logo' className='md:w-[25%] w-[50%] cursor-pointer' color='white' src="/finallogo.png" />
                        </Link>
                    </div>
                    <div>
                        <div className='xl:hidden block'>
                            <button onClick={() => setNavbarOpen(!navbarOpen)} className="group flex justify-center items-center hover:font-bold relative h-12 w-36 overflow-hidden rounded-lg bg-white text-lg shadow">
                                <div className="absolute inset-0 w-1 bg-lime-400 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                                <span className="relative text-black group-hover:text-slate-800 text-base">{navbarOpen ? "X" : "Menu"}</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={
                        "xl:flex w-full" +
                        (navbarOpen ? "flex transition duration-300" : "w-full hidden transition duration-300")
                    }
                >
                    <div class="w-auto sm:justify-center sm:flex md:flex md:justify-center md:items-center">
                        <ul className="font-medium items-center flex flex-col space-y-2 p-4 md:p-0 mt-4 rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent">
                            <li>
                            <Link to='/' class="group block p-2 text-gray-900 rounded-lg hover:bg-gray-100 md:hover:bg-transparent md:border-1 md:hover:text-lime-700 md:p-2 h-12 w-32 relative text-left leading-relaxed md:text-center" aria-current="page">
                                    <div className='absolute inset-0 w-0 bg-lime-400 transition-all duration-[250ms] ease-out group-hover:w-full rounded-lg'></div>
                                    <span className='relative text-black group-hover:text-slate-800 text-xl font-sans tracking-wide'>Home</span>
                                </Link>
                            </li>
                            <li>
                            <Link to='/tourplans' class="group block p-2 text-gray-900 rounded-lg hover:bg-gray-100 md:hover:bg-transparent md:border-1 md:hover:text-lime-700 md:p-2 h-12 md:w-44 relative text-left md:text-center leading-relaxed" aria-current="page">
                                    <div className='absolute inset-0 w-0 bg-lime-400 transition-all duration-[250ms] ease-out group-hover:w-full rounded-lg'></div>
                                    <span className='relative text-black group-hover:text-slate-800 text-xl font-sans tracking-wide'>Tour Packages</span>
                                </Link>
                            </li>
                            <li>
                            <Link to='/about' class="group block p-2 text-gray-900 rounded-lg hover:bg-gray-100 md:hover:bg-transparent md:border-1 md:hover:text-lime-700 md:p-2 h-12 w-32 md:text-center relative text-left leading-relaxed" aria-current="page">
                                    <div className='absolute inset-0 w-0 bg-lime-400 transition-all duration-[250ms] ease-out group-hover:w-full rounded-lg'></div>
                                    <span className='relative text-black group-hover:text-slate-800 text-xl font-sans tracking-wide'>About</span>
                                </Link>
                            </li>
                            <li>
                            <Link to='/contact' class="group block p-2 text-gray-900 rounded-lg hover:bg-gray-100 md:hover:bg-transparent md:border-1 md:hover:text-lime-700 md:p-2 h-12 md:w-44 md:text-center relative text-left leading-relaxed" aria-current="page">
                                    <div className='absolute inset-0 w-0 bg-lime-400 transition-all duration-[250ms] ease-out group-hover:w-full rounded-lg'></div>
                                    <span className='relative text-black group-hover:text-slate-800 text-xl font-sans tracking-wide'>Contact Us</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link to='/login' class="group block p-2 text-gray-900 rounded-lg hover:bg-gray-100 md:hover:bg-transparent md:border-1 md:hover:text-lime-700 md:p-2 h-12 w-32 md:text-center relative text-left leading-relaxed" aria-current="page">
                                    <div className='absolute inset-0 w-0 bg-lime-400 transition-all duration-[250ms] ease-out group-hover:w-full rounded-lg'></div>
                                    <span className='relative text-black group-hover:text-slate-800 text-xl font-sans tracking-wide'>Login</span>
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Header