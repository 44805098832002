import React from 'react'
import User from './passenger/User';
import Index from './mainDash/index';
import ListofUser from './passenger/ListofUser';
import Staff from './staff/Staff';
import ListofStaff from './staff/ListofStaff';


function Content({menuState, setMenuState}) {
  
  return (
    <div className=''>
      {/* {
        menuState === "add_user" ? <User/> : null
      }
      {
        menuState === "dashboard" ? <Index/> : null
      } */}
      
      {
        menuState === "add_user" ? <User/> : null
      }
      {
        menuState === "list_user" ? <ListofUser/> : null
      }
      {
        menuState === "add_staff" ? <Staff/> : null
      }
      {
        menuState === "list_staff" ? <ListofStaff/> : null
      }
      {
        menuState === "dashboard" ? <Index/> : null
      }
    </div>
  )
}

export default Content