import React from 'react'
import Header from './Header'
import Menu from './Menu';
import Content from './Content';
// import ListofUser from "./passenger/ListofUser"
import finallogo from "../finallogo.png"
import { Link } from 'react-router-dom';


function Dashboard() {

  const [menuState, setMenuState] = React.useState("dashboard");
  const [tabIndex, setTabIndex] = React.useState({
    show_passengers: false,
    show_reports: false,
    show_staff: false,
    show_db: false,
    show_chart: false,
  })

  // console.log("loooooool")

  // // function snackBarFunc() {
  // //   var x = document.getElementById("snackbar");
  // //   x.className = "show";
  // //   setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
  // // }

  return (
    <div className=''>
      <Header logo={finallogo} />
      <div className='flex space-x-5'>
        <div className='bg-[#f8f8f9]/20 backdrop-blur-md rounded-xl shadow-md m-2 w-1/7 z-0'><Menu tabIndex={tabIndex} setTabIndex={setTabIndex} menuState={menuState} setMenuState={setMenuState} /></div>
        <div className='w-5/6 m-4 z-0'><Content menuState={menuState} setMenuState={setMenuState}/></div>
      </div>
      
      
      
      
      
      
      
      <div className='sticky bottom-5 left-5 flex justify-end'>
      <Link to='/'>
        <button className='bg-lime-400 p-3 rounded-full hover:bg-lime-700'>
         Go to Website!
        </button>
        </Link>
      </div>
    </div>
  )
}

export default Dashboard;