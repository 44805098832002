import React from 'react';
import Header from '../Header';
import Footer from '../home/Footer';

function About() {

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <Header />
            <div>

                <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 scroll-smooth m-5">
                    <div className="flex flex-col lg:flex-row justify-evenly gap-8">
                        <div className="w-full flex flex-col justify-center">
                            <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4">About Us</h1>
                            <p className="font-normal text-lg leading-6 text-black"><span className='text-lime-600 font-bold'>ARL Holidays</span> is an experienced travel management company. We work with you to manage all elements of your travel in an efficient, cost effective and ethical manner.
                                <span className='text-lime-600 font-bold'>ARL Holidays</span> are committed to providing a professional service to our customers, ensuring they benefit from our experience, unique style and energy. A highly visible, independent and progressive travel agency, we aim to make a difference in everything we do.</p>
                        </div>
                        <div className="w-full lg:w-8/12">
                            <img className="w-full h-full rounded-lg object-left" src="/cover_images/cover_image_2.jpg" alt="A group of People" />
                        </div>
                    </div>

                    <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                        <div className="w-full flex flex-col justify-center">
                            <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">Our services</h1>
                            <ul className='text-lg leading-relaxed text-lime-800 font-semibold'>
                                <li>•	All Air Tickets – Domestic + International.</li>
                                <li>•	All kinds of hotel booking across the globe.</li>
                                <li>•	Conference Tour – Medical + Business.</li>
                                <li>•	Holiday Packages – Domestic + International.</li>
                                <li>•	Customize Tour Package.</li>
                                <li>•	International Train Tickets.</li>
                                <li>•	Transfer – Bus & Car – Domestic + International.</li>
                                <li>•	International Cruise Booking.</li>
                                <li>•	Ship Ticket Booking – Domestic + International</li>
                                <li>•	Visa Processing Service – Tourist + Medical + Business + Conference.</li>
                                <li>•	Medical Conference – Registration + Membership.</li>
                            </ul>
                        </div>
                        {/* <div className="w-full lg:w-8/12 lg:pt-8">
                            <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                                <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                    <img className="md:block hidden" src="https://i.ibb.co/FYTKDG6/Rectangle-118-2.png" alt="Alexa featured Image" />
                                    <img className="md:hidden block" src="https://i.ibb.co/zHjXqg4/Rectangle-118.png" alt="Alexa featured Image" />
                                    <p className="font-medium text-xl leading-5 text-gray-800 mt-4">Alexa</p>
                                </div>
                                <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                    <img className="md:block hidden" src="https://i.ibb.co/fGmxhVy/Rectangle-119.png" alt="Olivia featured Image" />
                                    <img className="md:hidden block" src="https://i.ibb.co/NrWKJ1M/Rectangle-119.png" alt="Olivia featured Image" />
                                    <p className="font-medium text-xl leading-5 text-gray-800 mt-4">Olivia</p>
                                </div>
                                <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                    <img className="md:block hidden" src="https://i.ibb.co/Pc6XVVC/Rectangle-120.png" alt="Liam featued Image" />
                                    <img className="md:hidden block" src="https://i.ibb.co/C5MMBcs/Rectangle-120.png" alt="Liam featued Image" />
                                    <p className="font-medium text-xl leading-5 text-gray-800 mt-4">Liam</p>
                                </div>
                                <div className="p-4 pb-6 flex justify-center flex-col items-center">
                                    <img className="md:block hidden" src="https://i.ibb.co/7nSJPXQ/Rectangle-121.png" alt="Elijah featured image" />
                                    <img className="md:hidden block" src="https://i.ibb.co/ThZBWxH/Rectangle-121.png" alt="Elijah featured image" />
                                    <p className="font-medium text-xl leading-5 text-gray-800 mt-4">Elijah</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="w-full lg:w-8/12">
                            <img className="w-full h-full rounded-lg object-left" src="/cover_images/cover_image_3.jpg" alt="A group of People" />
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default About