import './App.css';
import TourPakages from './components/main/tour_package/TourPakages';
import { BrowserRouter as Router, Routes, 
  Route } from "react-router-dom";
import About from './components/main/about/About'
import Contact from './components/main/contact/Contact'
import Index from './components/main/home/Index'
import SinglePakage from './components/main/tour_package/SinglePakage';
import Login from "./components/auth/Login"
import Dashboard from './components/dashboard/Dashboard';
import React from 'react'

function App() {
  return (
    <div className="App scroll-smooth">
      <Router>
        <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/tourplans" element={<TourPakages />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/package/:packageID/" element={<SinglePakage />} />
            <Route path="/login" element={<Login/>} />
            <Route path="/Dashboard" element={<Dashboard/>} />
          </Routes>          
      </Router>
    </div>
  );
}

export default App;
