import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React from 'react'
// import { Link } from 'react-router-dom';
// import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// import { styled } from '@mui/material/styles';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';



function Menu({ tabIndex, setTabIndex, menuState, setMenuState }) {

    // const Accordion = styled((props) => (
    //     <MuiAccordion disableGutters elevation={0} square {...props} />
    //   ))(({ theme }) => ({
    //     border: `1px solid ${theme.palette.divider}`,
    //     '&:not(:last-child)': {
    //       borderBottom: 0,
    //     },
    //     '&:before': {
    //       display: 'none',
    //     },
    //   }));
    
    //   const AccordionSummary = styled((props) => (
    //     <MuiAccordionSummary
    //       expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.6rem' }} />}
    //       {...props}
    //     />
    //   ))(({ theme }) => ({
    //     backgroundColor:
    //       theme.palette.mode === 'dark'
    //         ? 'rgba(50, 205, 50, 2)'
    //         : 'rgba(50, 205, 50, .12)',
    //     flexDirection: 'row-reverse',
    //     '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    //       transform: 'rotate(90deg)',
    //     },
    //     '& .MuiAccordionSummary-content': {
    //       marginLeft: theme.spacing(1),
    //     },
    //   }));
    
    //   const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    //     padding: theme.spacing(2),
    //     borderTop: '1px solid rgba(50, 205, 50, 2)',
    //   }));
    
    //   const [expanded, setExpanded] = React.useState('panel1');
    
    //   const handleChange = (panel) => (event, newExpanded) => {
    //     setExpanded(newExpanded ? panel : false);
    //   };

    return (
        <div className=''>
            <div className='min-w-[317px] h-screen '>
               
                <ul className='font-bold text-xl'>
                    <li>

                        <div className={menuState === "dashboard" ? 'flex text-[#1ba71b]  hover:border-l-4 hover:border-l-lime-500 focus:border-l-4 focus:border-l-lime-500 hover:text-[#2bca2b] cursor-pointer p-2 justify-between m-3 items-center' : 'flex text-[#1ba71b]  hover:border-l-4 hover:border-l-lime-500 focus:border-l-4 focus:border-l-lime-500 hover:text-[#2bca2b] cursor-pointer p-2 justify-between m-3 items-center'} onClick={() => setMenuState("dashboard")}>
                            <span className='' >Dashboard</span>
                        </div>

                    </li>
                    <li>
                        <div className='flex text-[#1ba71b]  hover:border-l-4 hover:border-l-lime-500 hover:text-[#2bca2b] cursor-pointer  p-2 justify-between m-3 items-center' onClick={() => setTabIndex({ ...tabIndex, show_passengers: !tabIndex.show_passengers })}>
                            <span>Records</span>
                            {tabIndex.show_passengers ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>}
                        </div>
                        {tabIndex.show_passengers ? <ul className='font-medium ml-5'>
                            <li className={menuState === "add_user" ? 'hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 ' : 'hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '} onClick={() => setMenuState("add_user")}>Add User</li>
                            <li className={menuState === "list_user" ? 'hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5  ' : 'hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '} onClick={() => setMenuState("list_user")}>List User</li>
                           
                        </ul> : null}
                    </li>
                    <li>
                        <div className='flex text-[#1ba71b]  hover:border-l-4 hover:border-l-lime-500 hover:text-[#2bca2b] p-2 cursor-pointer   justify-between m-3 items-center' onClick={() => setTabIndex({ ...tabIndex, show_reports: !tabIndex.show_reports })}>
                            <span>Reports</span>
                            {tabIndex.show_reports ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>}
                        </div>
                        {tabIndex.show_reports ? <ul className='font-medium ml-5'>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>Provider Reports</li>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>Clients Reports</li>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>Service Reports</li>
                        </ul> : null}
                    </li>
                    <li>
                        <div className='flex text-[#1ba71b]  hover:border-l-4 hover:border-l-lime-500 hover:text-[#2bca2b] p-2  cursor-pointer  justify-between m-3 items-center' onClick={() => setTabIndex({ ...tabIndex, show_staff: !tabIndex.show_staff })}>
                            <span>Staff</span>
                            {tabIndex.show_staff ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>}
                        </div>
                        {tabIndex.show_staff ? <ul className='font-medium ml-5'>
                        <li className={menuState === "add_staff" ? 'hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5  ' : 'hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '} onClick={() => setMenuState("add_staff")}>Add Staff</li>
                        <li className={menuState === "list_staff" ? 'hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5  ' : 'hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '} onClick={() => setMenuState("list_staff")}>List of Staff</li>
                           
                        </ul> : null}
                    </li>
                    <li>
                        <div className='flex text-[#1ba71b]  hover:border-l-4 hover:border-l-lime-500 hover:text-[#2bca2b] p-2 cursor-pointer  justify-between m-3 items-center' onClick={() => setTabIndex({ ...tabIndex, show_db: !tabIndex.show_db })}>
                            <span>DB</span>
                            {tabIndex.show_db ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>}
                        </div>
                        {tabIndex.show_db ? <ul className='font-medium ml-5'>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>Passenger</li>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>Clients</li>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>Service Provider</li>
                        </ul> : null}
                    </li>
                    <li>
                        <div className='flex text-[#1ba71b]  hover:border-l-4 hover:border-l-lime-500 hover:text-[#2bca2b] p-2 cursor-pointer  justify-between m-3 items-center' onClick={() => setTabIndex({ ...tabIndex, show_charts: !tabIndex.show_charts })}>
                            <span>CHARTS</span>
                            {tabIndex.show_charts ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>}
                        </div>
                        {tabIndex.show_charts ? <ul className='font-medium ml-5'>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>PIE CHART</li>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>BAR CHART</li>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>WORLD MAP</li>
                            <li className='hover:border-l-2 hover:border-l-lime-500 text-lime-700 hover:text-lime-400 text-base cursor-pointer m-1 pl-5 '>LINE CHART</li>
                        </ul> : null}
                    </li>
                </ul>
            </div>
            {/* <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>\</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              fontSize={12}
                            >
                              hello
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        <div>
                            <p>Dashboard</p>
                        </div>
                        <div>
                            <p>Add Passenger</p>
                        </div>
                        <div>
                            <p>List Of Passenger</p>
                        </div>
                        <div>
                            <p>Staff Reports</p>
                        </div>
                        <div>
                            <p>Charts</p>
                        <ul>
                            <li>Pie Charts</li>
                            <li>Bar Charts</li>
                            <li>World Map</li>
                            <li>Line Charts</li>
                        </ul>
                        </div>
                        
            </div> */}
            
        </div>
    )
}

export default Menu

// {/* <div className='bg-orange-400 relative' style={{marginLeft: "260px"}}>
//                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//             </div> */}