import React, { useRef, useState } from 'react'
// import { useStateValue } from '../../context/StateProvider';
// import { useNavigate } from 'react-router-dom';
// import Checkbox from '@mui/material/Checkbox';
// import finallogo from '../finallogo.png';
// import bgimg from '../main/cover_images/arl_cover_img.jpg'
import Header from "../main/Header"
import "./login.css";
// import { CheckBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
// import { Dashboard } from '@mui/icons-material';
import Dashboard from '../dashboard/Dashboard';

function Login() {
    // const [userOBJ, setUserOBJ] = useState({
    //     email: "ariksarkar@gmail.com",
    //     password: "helloworld",
    // });

    // // const [state, dispatch] = useStateValue();

    // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    // // console.log(state);

    // const changeHandler = (event) => {
    //     setUserOBJ({ ...userOBJ, [event.target.name]: event.target.value });
    // }
    // const addUser = (event) => {
    //     // dispatch the items into data layer
    //     // dispatch({
    //     //     type:"LOGIN",
    //     //     payload: userOBJ
    //     // });
    //     event.preventDefault();
    // };
    // const navigate = useNavigate();
    // const navi = () => {
    //     navigate('/Dashboard');
    // };
    const email = useRef()
    const password = useRef()
    const getEmail=localStorage.getItem("emailData")
    const getPassword=localStorage.getItem("passwordData")


    const handleSubmit = () => {
        if(email.current.value=="admin@arlholidays.com"&&password.current.value=="12345678")
        {
            localStorage.setItem("emailData","admin@arlholidays.com")
            localStorage.setItem("passwordData","12345678")
        }
    }




    return (
        <>
            { getEmail&&getPassword?
                        <Dashboard/>:
            <div>
                <div>
                    <Header />
                </div>
                <div className='mt-4 grow flex items-center justify-around'>
                    <div className=''>
                        <div className=' w-full'>
                            <h1 className='text-5xl text-center mb-4 w-full font-serif tracking-wider font-bold'>Login</h1>
                            <div className='w-[100%] border-b border-b-lime-400' ></div>
                        </div>
                        
                        

                        <form className='max-w-md mx-auto border m-10 px-10 py-20 w-[50vh] rounded-lg' onSubmit={handleSubmit}>
                            <div>
                                <p className='text-gray-600 font-serif font-bold tracking-wider'>Username / Email</p>


                                <input className='border w-full rounded-lg py-3 mt-5 px-3' type="email" placeholder='Enter field' ref={email} />
                            </div>

                            <div className='py-5'>
                                <p className='text-gray-600 font-serif font-bold tracking-wider'>Password</p>

                                <input className='border w-full rounded-lg py-3 mt-5 px-3' type="password" placeholder='Password' ref={password}/>

                            </div>

                            <button className='bg-lime-400 border w-full rounded-lg py-3 mt-5' >
                                {/* <Link to="/Dashboard">Login</Link> */}
                                <button>Login</button>
                            </button>
                            <div>
                                <p className='text-gray-400 text-center py-3'>***only for admin</p>
                            </div>
                        </form>
                        
                    </div>

                </div>
            </div>
            }
        </>

    )
}

export default Login

{/* <section className="h-screen w-screen flex justify-center">

                <div className="container pt-[10%] h-full px-6 py-24">
                    <div
                        className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
                        <div className="mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
                            <img
                                src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                                className="w-full"
                                alt="Phoneimage" />
                        </div>
                        <div className="md:w-8/12 lg:ml-6 lg:w-5/12 border p-20 bg-lime-50 rounded-xl">
                            <p className='leading-relaxed text-2xl p-3 tracking-wide' >Welcome back,</p>
                            <p className='w-[120%] pl-3 pt-2 pb-5 leading-relaxed' >Start your website in seconds. Don’t have an account? <span className='cursor-pointer hover:underline hover:text-lime-600' >Sign up.</span></p>
                            <form onSubmit={addUser}>
                                <div className="relative mb-6 border p-2 rounded-xl hover:bg-lime-100" data-te-input-wrapper-init>
                                    <label className="form-label inline-block mb-2 text-gray-700">Email address</label>
                                    <input
                                        className="peer block min-h-[auto] w-full rounded-xl border-0  py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear  bg-white/50  focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                        id="exampleFormControlInput3"
                                        placeholder="Email address" type="email" name='email' value={userOBJ.email} onChange={(e) => changeHandler(e)} />

                                </div>

                                <div className="relative mb-6 border p-2 rounded-xl hover:bg-lime-100" data-te-input-wrapper-init>
                                    <label className="form-label inline-block mb-2 text-gray-700">Password</label>
                                    <input
                                        type="password" name='password' value={userOBJ.password} onChange={(e) => changeHandler(e)}
                                        className="peer block min-h-[auto] w-full rounded-xl  border-0  py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear  bg-white/50 focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                        id="exampleFormControlInput33"
                                        placeholder="Password" />

                                </div>


                                <div className="mb-6 flex items-center justify-between">
                                    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                        <Checkbox {...label} defaultChecked color="success" ></Checkbox>
                                        <label className="form-check-label inline-block text-gray-800">Remember me</label>
                                    </div>
                                    <a
                                        href="#!"
                                        className="text-lime-500 hover:text-[#00B712]/80 focus:text-lime-400 transition duration-200 ease-in-out"
                                    >Forgot password?</a
                                    >
                                </div>
                                <section className="h-screen w-screen flex justify-center">
                
                <Header/>

                <div className="container pt-[10%] h-full px-6 py-24">
                    <div
                        className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
                        <div className="mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
                            <img
                                src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                                className="w-full"
                                alt="Phoneimage" />
                        </div>
                        <div className="md:w-8/12 lg:ml-6 lg:w-5/12 border p-20 bg-lime-50 rounded-xl">
                            <p className='leading-relaxed text-2xl p-3 tracking-wide' >Welcome back,</p>
                            <p className='w-[120%] pl-3 pt-2 pb-5 leading-relaxed' >Start your website in seconds. Don’t have an account? <span className='cursor-pointer hover:underline hover:text-lime-600' >Sign up.</span></p>
                            <form onSubmit={addUser}>
                                <div className="relative mb-6 border p-2 rounded-xl hover:bg-lime-100" data-te-input-wrapper-init>
                                    <label className="form-label inline-block mb-2 text-gray-700">Email address</label>
                                    <input
                                        className="peer block min-h-[auto] w-full rounded-xl border-0  py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear  bg-white/50  focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                        id="exampleFormControlInput3"
                                        placeholder="Email address" type="email" name='email' value={userOBJ.email} onChange={(e) => changeHandler(e)} />

                                </div>

                                <div className="relative mb-6 border p-2 rounded-xl hover:bg-lime-100" data-te-input-wrapper-init>
                                    <label className="form-label inline-block mb-2 text-gray-700">Password</label>
                                    <input
                                        type="password" name='password' value={userOBJ.password} onChange={(e) => changeHandler(e)}
                                        className="peer block min-h-[auto] w-full rounded-xl  border-0  py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear  bg-white/50 focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                        id="exampleFormControlInput33"
                                        placeholder="Password" />

                                </div>


                                <div className="mb-6 flex items-center justify-between">
                                    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                        <Checkbox {...label} defaultChecked color="success" ></Checkbox>
                                        <label className="form-check-label inline-block text-gray-800">Remember me</label>
                                    </div>
                                    <a
                                        href="#!"
                                        className="text-lime-500 hover:text-[#00B712]/80 focus:text-lime-400 transition duration-200 ease-in-out"
                                    >Forgot password?</a
                                    >
                                </div>
                                <Link to="/Dashboard"
                                    type="submit"
                                    className="flex justify-center items-center w-full p-2 rounded shadow-md bg-gradient-to-r from-[#5AFF15] to-[#00B712] hover:from-[#233329] hover:to-[#63D471] transition duration-150 ease-in-out"
                                    data-te-ripple-init
                                    data-te-ripple-color="light">
                                    Sign in
                                </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </section>                    <Link to="/Dashboard"
                                    type="submit"
                                    className="flex justify-center items-center w-full p-2 rounded shadow-md bg-gradient-to-r from-[#5AFF15] to-[#00B712] hover:from-[#233329] hover:to-[#63D471] transition duration-150 ease-in-out"
                                    data-te-ripple-init
                                    data-te-ripple-color="light">
                                    Sign in
                                </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}