import React from 'react'

function ListofUser() {
  const users = [
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
    {
      id: 1,
      name: 'John Doe',
      email: 'johndoe@example.com',
      phone: '555-555-1234',
      address: '123 Main St, Anytown USA 12345'
    },
    {
      id: 2,
      name: 'Jane Doe',
      email: 'janedoe@example.com',
      phone: '555-555-5678',
      address: '456 Elm St, Anytown USA 12345'
    },
  ];

  return (
    <div>
      <div className="container mx-auto px-4">
      <h1 className="text-2xl font-bold mb-4">User List</h1>
      <div className="">
      <div className="pr-4 pl-4 pt-4 pb-2 flex justify-between items-center text-center">
            <h2 className="text-lg font-medium">Name</h2>
            <p className="text-lg font-medium text-gray-600">Email</p>
            <p className="text-lg font-medium text-gray-600">Phone Number</p>
            <p className="text-lg font-medium text-gray-600">Address</p>
            <h2 className="text-lg font-medium">Name</h2>
            <p className="text-lg font-medium text-gray-600">Email</p>
            <p className="text-lg font-medium text-gray-600">Phone Number</p>
            <p className="text-lg font-medium text-gray-600">Address</p>
          </div>
        {users.map(user => (
          <div key={user.id} className="bg-white p-4 rounded-md shadow flex justify-between items-center">
            <h2 className="text-gray-600">{user.name}</h2>
            <p className="text-gray-600">{user.email}</p>
            <p className="text-gray-600">{user.phone}</p>
            <p className="text-gray-600">{user.address}</p>
            <h2 className="text-gray-600">{user.name}</h2>
            <p className="text-gray-600">{user.email}</p>
            <p className="text-gray-600">{user.phone}</p>
            <p className="text-gray-600">{user.address}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}

export default ListofUser