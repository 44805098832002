import React, { useState, useRef, useEffect } from 'react';
import Header from '../Header';
import { data } from "../data"
import { useParams, Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import Footer from '../home/Footer';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { red } from '@mui/material/colors';
import './SinglePakage.css';  // Import custom styles

function SinglePakage() {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mainImage, setMainImage] = useState('');

  const { packageID } = useParams();
  let navigate = useNavigate();

  const [mydata, setMydata] = React.useState(null);

  React.useEffect(() => {
    setMydata(data);
  }, []);

  let plan = null;
  let planimages = [];

  if (mydata) {
    plan = mydata.cardData.find((item) => {
      let itemID = item.id.toString();
      planimages = item.slider_images;
      return itemID === packageID;
    });
  }

  React.useEffect(() => {
    setMainImage(planimages[0]);
  }, [planimages]);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.6rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(50, 205, 50, 2)'
        : 'rgba(50, 205, 50, .12)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(50, 205, 50, 2)',
  }));

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const modalRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <>
      <Header />

      <div className=''>
        {plan &&
          <div className='flex flex-col justify-center items-center'>
            <h2 className="text-sm title-font text-gray-400 font-extrabold pt-2 tracking-widest">ARL Holidays presents</h2>
            <h1 className='border-b flex justify-center md:text-5xl text-3xl p-2 font-bold font-serif border-b-lime-500 text-center'>{plan.name}</h1>
          </div>
        }
      </div>
      <div className=''>
        <div className=''>
          {plan &&
            <section className="text-gray-700 body-font overflow-hidden m-5 bg-white">
              <div className="container mx-auto">
                <div className="flex-col flex lg:flex-row lg:space-x-10">
                  <div className='flex-col items-center lg:w-[60%]'>
                    <div className='flex justify-center'>
                      <img alt='ac' className='w-[60vh] object-cover lg:h-[30rem] h-[20rem] rounded-lg relative object-center cursor-pointer' src={mainImage} onClick={openModal} />
                    </div>
                    <div className=''>
                      <div className='overflow-x-hidden flex lg:h-[30rem] h-[15rem]'>
                        {planimages.map((each, index) => (
                          <div className='mt-3 gallery' key={index}>
                            <img
                              onClick={() => setMainImage(each)}
                              className='rounded-lg hover:scale-95 block w-[100%] h-[10rem] transition-all duration-300 relative object-center p-1 shadow-lg cursor-pointer object-cover shadow-lime-100'
                              alt={each.name} src={each} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-[60%]">
                    <div className=''>
                      <div className='text-center py-5 my-2 bg-lime-50/50 hover:bg-lime-100 rounded-lg flex justify-start space-x-20 px-10 items-center'>
                        <div>
                          <DarkModeOutlinedIcon />
                          <p className='font-bold text-lg marker:tracking-wide'>Itinerary</p>
                        </div>
                        <p className="leading-relaxed font-semibold text-xl pt-3">{plan.packages[plan.default].display_text}</p>
                      </div>
                      <div className=' text-center py-5 my-2 bg-lime-50 hover:bg-lime-100 rounded-lg flex justify-start space-x-20 px-10  items-center'>
                        <div>
                          <AssignmentTurnedInOutlinedIcon />
                          <p className='font-bold text-lg  marker:tracking-wide'>Includes</p>
                        </div>
                        <p className="leading-relaxed font-semibold">{plan.packages[plan.default].include}</p>
                      </div>
                      <div className='text-center p-5 my-2 bg-lime-50 hover:bg-lime-100 rounded-lg flex space-x-10 px-10 items-center'>
                        <div>
                          <RoomOutlinedIcon />
                          <p className='font-bold text-lg marker:tracking-wide'>Places Covered</p>
                        </div>
                        <p className="leading-relaxed text-left font-bold text-sm">{plan.packages[plan.default].cover}</p>
                      </div>
                    </div>
                    <div className='md:flex justify-evenly font-semibold bg-lime-50 hover:bg-lime-100 p-8 gap-3 mb-5 rounded-lg'>
                      <div className='md:w-[50%]'>
                        <p className='font-bold text-xl'>Inclusions : </p>
                        {plan.packages[plan.default].inclusions.map((each, index) => (
                          <ul key={index} className='flex items-center space-x-1 pt-1 list-disc'>
                            <li className='text-lg font-mono text-green-800 hover:text-green-900'>{each}</li>
                          </ul>
                        ))}
                      </div>
                      <div className='md:w-[50%]'>
                        <p className='font-bold text-xl'>Exclusion :</p>
                        {plan.packages[plan.default].exclusions.map((each, index) => (
                          <ul key={index} className='flex items-center space-x-1 pt-1 list-disc'>
                             <li className='text-lg font-mono text-red-800 hover:text-red-900'>{each}</li>
                            </ul>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-between mt-6 border-t-2 p-4 border-gray-200">
                      <div className='space-x-2'>
                        <span className='text-2xl font-bold'>Tour Costing: </span>
                        <span className="title-font font-bold text-xl text-lime-900 border-b">{plan.packages[plan.default].display_pricing}<span className='text-xs'>Per Person</span></span>
                      </div>
                      <div className='flex items-center space-x-4'>
                        <Link to="/contact" className="flex ml-auto text-white bg-lime-600 border-0 py-2 px-6 focus:outline-none hover:bg-lime-700 rounded">Contact Now</Link>
                        <Checkbox {...label} icon={<FavoriteBorder
                          sx={{
                            color: red[800],
                            '&.Mui-checked': {
                              color: red[600],
                            },
                          }}
                        />} checkedIcon={<Favorite
                          sx={{
                            color: red[800],
                            '&.Mui-checked': {
                              color: red[600],
                            },
                          }}
                        />} />
                      </div>
                    </div>
                    <div className='text-sm'>*{plan.packages[plan.default].pricing_dec}</div>
                  </div>
                </div>
              </div>
            </section>
          }
        </div>
      </div>

      <div className='m-16 pt-20 border px-10 pb-10 border-transparent shadow-xl shadow-lime-200'>
        <h1 className='text-2xl font-bold tracking-wide'>Frequently Asked Questions -</h1>
        <div className="pt-3">
          <ul>
            <li>
              <div className='m-4'>
                <h1 className='text-lg font-semibold'>Visa Requirement: </h1>
                <p className='text-sm pl-4'>Last 3 Months Bank Statement.
                  1 Copy passport size Photo in Matt Paper.
                  Original passport.
                  Minimum time to get visa is 25 Working Days.
                </p>
              </div>
            </li>
            <li>
              <div className='m-4'>
                <h1 className='text-lg font-semibold'>Are courier charges included in the fees?</h1>
                <p className='text-sm pl-4'>Only one way courier charges are included. You will have to courier the documents initially to the address as communication by the agent. Once visas are approved we will courier back the documents.</p>
              </div>
            </li>
            <li>
              <div className='m-4'>
                <h1 className='text-lg font-semibold'>How long does it take to get a visa?</h1>
                <p className='text-sm pl-4'>Once we receive all the documents, we will apply for the visa within 24 hours if all documents are correct. Once applied, it usually takes 3-4 working hours for visa approval</p>
              </div>
            </li>
            <li>
              <div className='m-4'>
                <h1 className='text-lg font-semibold'>Do I need to courier all the documents including my original passport?</h1>
                <p className='text-sm pl-4'>We would require original documents, employment proof, cover letter, form 14A and other documents as shared the agent post booking confirmation.</p>
              </div>
            </li>
            <li>
              <div className='m-4'>
                <h1 className='text-lg font-semibold'>How will I get my Visa?</h1>
                <p className='text-sm pl-4'>Once visa is approved, we will courier back your original passport and e-visa copy.</p>
              </div>
            </li>
            <li>
              <div className='m-4'>
                <h1 className='text-lg font-semibold'>What all documents are required at Immigration?</h1>
                <p className='text-sm pl-4'>Below are the list of documents required at immigration-Original passport, Print out of your e-visa, Onward and return air tickets, Accommodation proof (hotel vouchers or residential proof).</p>
              </div>
            </li>
            <li>
              <div className='m-4'>
                <h1 className='text-lg font-semibold'>Are there any additional documents which will be required for processing?</h1>
                <p className='text-sm pl-4'>Additional documents are required only in certain cases and the conditions are mentioned in the document list. If required, our agent will let you know post booking.</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content" ref={modalRef}>
            <img alt='large view' className='modal-image' src={mainImage} />
          </div>
          <span className="close" onClick={closeModal}>&times;</span>
        </div>
      )}
      <Footer />
    </>
  );
}

export default SinglePakage;
