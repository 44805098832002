import React from 'react';
import Header from '../Header';
import Home from './Home'

function Index() {
  return (
    <div>
        <Header/>
        <Home/>
    </div>
  )
}

export default Index