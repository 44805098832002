import React from 'react';
import { Link } from 'react-router-dom';
import { data } from "../data";
import "./Home.css"
import Footer from './Footer';

function MainHome() {

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div className=''>

      <div className='font-mono '>
        <div className='w-[100%] p-4'>
          <img src="/cover_images/arl_cover_img.jpg" alt="arl-holidays-background" className='rounded-xl h-[30vh] md:h-full object-cover object-right' />
        </div>


      </div>
      <div className='pt-20'>
        <div className='text-center z-10 flex justify-center font-serif font-bold text-3xl'>
          <h1 className='border-b border-b-lime-500 w-96 z-40 text-center text-4xl'>Most Popular Tours</h1>
        </div>
        <div>
          <div className='carousel'>
            {data && data.cardData.map((each, index) => {
              return (
                index < 6 && <div key={each.id}>
                  <Link to={`/package/${each.id}`} key={index}>
                    <div className='relative group rounded-lg'>
                      <img
                        className='imags rounded-lg md:h-[10rem]'
                        src={each.cover_image} alt={each.name} />                    
                      <div class="group group-hover:bg-lime-100 absolute flex justify-center items-center hover:font-bold top-10 left-10 overflow-hidden rounded-lg bg-white text-lg shadow">
                        <span class="text-black group-hover:text-slate-800 text-2xl font-serif tracking-wide ">{each.name}</span>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>


        </div>

        <div className='absolute flex justify-center w-[100%] p-10'>          
          <Link to="/tourplans" class="relative inline-flex items-center px-12 py-3 overflow-hidden text-lg font-medium text-lime-600 border-2 border-lime-600 rounded-xl hover:text-white group hover:bg-gray-50">
            <span class="absolute left-0 block w-full h-0 transition-all bg-lime-600 opacity-100 group-hover:h-full top-0 group-hover:top-0 duration-600 ease"></span>
            <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
            </span>
            <span to='/tourplans' class="relative font-sans tracking-wider">View All</span>
          </Link>
        </div>
      </div>
      <div className='md:mt-24 font-serif pt-56'>
        <div>
          <div className='md:flex justify-center text-center items-center' >
            <h1 className='text-5xl md:text-6xl font-serif'>Why Book with Us</h1>
            <div className='border md:w-32 mr-3 ml-3 border-lime-500' ></div>
            <p className='text-2xl md:w-[80vh]'>We recognize that no two clients are alike thus every individual traveler is important to us. <span className='text-[#32CD32] font-bold' >ARL Holidays</span> is able to organize travel related any services in Outbound.</p>
          </div>
          <div className='grid md:grid-cols-3 grid-cols-1 xl:grid-cols-2 mt-8 items-center' >
            <div className='m-8 '>
              <h1 className='text-3xl'>Best in Business</h1>
              <span className='border-b-2 border-b-[#32CD32] pr-[100%]'></span>
              <p className='text-lg'><span className='text-[#32CD32] font-semibold' >ARL Holidays</span> believes in service, promptness and is capable to provide all travel related services.</p>
            </div>

            <div className='m-8 '>
              <h1 className='text-3xl'>100% Customizable</h1>
              <span className='border-b-2 border-b-[#32CD32] pr-[100%]'></span>
              <p className='text-lg'>Tell us about your trip requirement. We'll work together to customize your trip to meet your exact requirement so that you have a memorable trip.</p>
            </div>

            <div className='m-8 '>
              <h1 className='text-3xl'>Best Price Guarantee</h1>
              <span className='border-b-2 border-b-[#32CD32] pr-[100%]'></span>
              <p className='text-lg'><span className='text-[#32CD32]' >ARL Holidays</span> has some of the best prices anywhere in Bangladesh. And that's a promise we stand behind.</p>
            </div>

            <div className='m-8'>
              <h1 className='text-3xl'>No Hidden Charges</h1>
              <span className='border-b-2 border-b-[#32CD32] pr-[100%]'></span>
              <p className='text-lg'>We don't add hidden extras cost. All trips include travel permit, lodging and fooding. There are no surprises with hidden costs.</p>
            </div>

            <div className='m-8'>
              <h1 className='text-3xl'>Local Experts. <span className='text-green-600'>Middle-man Free Pricing</span></h1>
              <span className='border-b-2 border-b-[#32CD32] pr-[100%]'></span>
              <p className='text-lg'>We're a local travel agency. When you book with us, you get best possible price, which is middle-man free.</p>
            </div>

            <div className='m-8 '>
              <h1 className='text-3xl'>World Class Service</h1>
              <span className='border-b-2 border-b-[#32CD32] pr-[100%]'></span>
              <p className='text-lg'><span className='text-[#32CD32]' >ARL Holidays</span> is created with the fresh idea of looking at tourism from the growing trend and future prospects.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default MainHome