import React from 'react';
import stats from './icons/line-chart.gif';
import TourIcon from '@mui/icons-material/Tour';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AccessibilityIcon from '@mui/icons-material/Accessibility';

function UserNotification() {
  return (
    <div className='pt-2 pr-3 pb-4'>
      <div className='flex md:grid-cols-4 grid-cols-2 justify-between'>
      <div className='border w-56 h-fit p-2 rounded-xl text-left'>
          <span className=' my-3 tracking-widest'>Total Visits</span>
          <div className='flex items-center justify-between'>
          {/* <img src={stats} alt='' className='w-10' />  */}
          <TourIcon/>
          <span className='border-transparent px-3 py-1 bg-red-100 rounded-xl'>5</span>
          </div>
        </div>

        <div className='border w-56 h-fit p-2 rounded-xl text-left'>
          <span className=' my-3 tracking-widest'>Recent Booking</span>
          <div className='flex items-center justify-between'>
          {/* <img src={stats} alt='' className='w-10' />  */}
          <TaskAltIcon/>
          <span className='border-transparent px-3 py-1 bg-green-100 rounded-xl'>5</span>
          </div>
        </div>  

        <div className='border w-56 h-fit p-2 rounded-xl text-left'>
          <span className=' my-3 tracking-widest'>New Users</span>
          <div className='flex items-center justify-between'>
          {/* <img src={stats} alt='' className='w-10' />  */}
          <GroupAddIcon/>
          <span className='border-transparent px-3 py-1 bg-purple-100 rounded-xl'>7</span>
          </div>
        </div>  


        <div className='border w-56 h-fit p-2 rounded-xl text-left'>
          <span className=' my-3 tracking-widest'>Total Staff</span>
          <div className='flex items-center justify-between'>
          {/* <img src={stats} alt='' className='w-10' />  */}
          <AccessibilityIcon/>
          <span className='border-transparent px-3 py-1 bg-blue-100 rounded-xl'>14</span>
          </div>
        </div>          
      </div>
    </div>
  )
}

export default UserNotification