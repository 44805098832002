import React from 'react'
import ReactApexChart from "react-apexcharts"

function BookingCharts() {
    const series = [{
        name: 'Inflation',
        data: [10, 12, 14, 16, 8, 10, 13, 12, 14, 13, 18, 2]
      }];
      const options =  {
        colors : ['#2bca2b', '#2bca2b'],
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: 'center', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "";
          },
          offsetY: 5,
          style: {
            fontSize: '14px',
            colors: ["#007500"]
          }
        },
        
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'color',
              gradient: {
                colorFrom: '#007500',
                colorTo: '#007500',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "";
            }
          }
        
        },
        title: {
          text: 'Current Year ( 2022 ) bookings',
          floating: true,
          offsetY: 380,
          align: 'center',
          style: {
            color: '#007500'
          }
        }
      };
     
 return (
    <div>
        <div className='border w-fit p-10 border-opacity-50 rounded-xl'>
        <ReactApexChart options={options} series={series} type="bar" height={400} width={700} />
        </div>
    </div>
  )
}

export default BookingCharts