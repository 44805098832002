import React from 'react';
import BookingCharts from './BookingCharts';
import Calender from './Calender';
import UserNotification from './UserNotification';
import Line from './Line';

function index() {

//  console.log(<UserNotification/>)
//   console.log(<BookingCharts/>)
//   console.log(<Calender/>)
//   console.log(<Line/>)

  return (
    <div className=''>
        
        {/* <UserNotification/>
        <div className='flex items-center justify-evenly m-3'>
        <BookingCharts/>
        <Calender/>
        </div>
        <div>
          <Line/>
        </div> */}
        <UserNotification/>
        <div className='grid grid-cols-2 gap-10'>
          <div className='flex justify-between gap-20 items-center'>
          <BookingCharts/>
        <Calender/>
          </div>
        <div>
          
        </div>
        <Line/>
        </div>
        
    </div>
  )
}

export default index