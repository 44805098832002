import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';


function Footer() {

    const getCurrentYear = () => {
        const d = new Date();
        return d.getFullYear();
    }

    function handlePhoneClick(phoneNumber) {
        window.location.href = `tel:${phoneNumber}`;
      }
    return (
        <div>
            <div id='footer' className='pt-7 font-mono' >
                <footer class="text-center lg:text-left bg-lime-100 text-black">
                    <div class="flex justify-between items-center lg:justify-between p-6 border-b border-lime-300">
                        <div class="mr-12 block">
                            <span>Get connected with us on social networks:</span>
                        </div>
                        <div class="flex justify-center">
                            <a href="https://www.facebook.com/arlholidays" target='_blank' class="mr-6 text-black">
                                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
                                    class="w-2.5" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512">
                                    <path fill="currentColor"
                                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                    </path>
                                </svg>
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=%2B8801912973025&context=ARB_ah8sU8Eq0QmkuK5DrpT6qztixa_P_A8IoNgqvxKAAxKt57seBRQ8OpFFk_jGuyeSDmhSPeuFIgKWU6IBMji8rc1R2GDQ_4QsuNSjVt_yn5iq_gXwjQZMfjmZgP7MIFx056nHYY0ee0lMxeGB-0j7lQ&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwZXh0bgNhZW0CMTAAAR1w2XJTeu50jMvOPWudpwYje0ZNqRfdUA5Sw_2Rm3Q-lKCm39VwKntucoI_aem_ZgDI-zOsHmHo0UykBdacjw" target='_blank' class="mr-6 text-black">
                                <img class="w-4" src="https://pngimg.com/d/whatsapp_PNG95166.png" alt="insta" />
                            </a>
                        </div>
                    </div>
                    <div class="mx-6 py-10 text-center md:text-left">
                        <div class="md:flex sm:flex-col justify-between space-y-10 items-center">
                            <div className='md:flex md:justify-evenly md:items-center'>
                                <div>
                                    <div className="md:w-[50%]">
                                        <img className="w-[100%]" src="/finallogo.png" alt="A group of People" />
                                    </div>
                                </div>
                                <div class="md:w-[50%]">
                                    <h6 class="uppercase font-semibold mb-4 flex items-center justify-center md:justify-start ">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                                            class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z">
                                            </path>
                                        </svg>
                                        Why Book with Us
                                    </h6>
                                    <p className='break-normal'>
                                        ARL Holidays Tours & Travels is one of the most preferential travel agency with years of experience & excellent money value. It arranges seamlessly professional domestic & international trips for couples, families & businessmen worldwide
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className='md:flex md:justify-between sm:flex-col sm:justify-center pt-10'>
                            <div className='flex justify-start md:px-10'>
                                <div class="md:w-[47%]">
                                    <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                        Contact
                                    </h6>
                                    <p class="flex items-center break-normal justify-center md:justify-start mb-4 cursor-pointer">

                                        <img alt='home_img' className='mr-4 w-4' src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Home-icon.svg/1024px-Home-icon.svg.png' />

                                        Sonartori Tower,
                                        8th Floor, 12 Soanrgaon
                                        Road, Bangla Motor,
                                        Dhaka - 1000
                                    </p>
                                    <p class="flex items-center justify-center md:justify-start mb-4 cursor-pointer">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                                            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                            </path>
                                        </svg>
                                        arlholidays3@gmail.com
                                    </p>
                                    <p class="flex items-center justify-center md:justify-start mb-4 cursor-pointer">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                                            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                            </path>
                                        </svg>
                                        info@arlholidays.com
                                    </p>
                                    <p class="flex items-center justify-center md:justify-start mb-4 cursor-pointer">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                            </path>
                                        </svg>
                                        
                                        <a onClick={() => handlePhoneClick('+880 1737083238')}>+880 1737083238</a>
                                    </p>
                                    <p class="flex items-center justify-center md:justify-start mb-4 cursor-pointer">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                            </path>
                                        </svg>
                                        
                                        <a onClick={() => handlePhoneClick('+880 1715010444')}>+880 1715010444</a>
                                    </p>
                                    <p class="flex items-center justify-center md:justify-start mb-4 cursor-pointer">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                            class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                            </path>
                                        </svg>
                                        <a onClick={() => handlePhoneClick('+880 1717098276')}>+880 1717098276</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="text-center p-6 bg-green-400">
                        <span className='text-black'> &#169;&#174; {getCurrentYear()} Copyright: </span>
                        <a class="text-black hover:text-lime-600 font-semibold" href="https://tailwind-elements.com/">ARL holidays.</a>
                        <span> Travel Agency in 🇧🇩 | Built with <FavoriteIcon sx={{
                            color: red[800]
                        }} /> by <a href="https://www.facebook.com/ArikASSarkar" target="_blank" rel="noreferrer" style={{ color: "blue", textDecoration: "underline" }}>Arik</a> &amp; <a href="https://www.instagram.com/arpitsarkar16/" target="_blank" rel="noreferrer" style={{ color: "blue", textDecoration: "underline" }}>Arpit</a> </span>
                        <span className='font-bold'> </span>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer