export const data = {
  cardData: [
    {
      id: 1,
      name: "Bali",
      cover_image: "/cover_images/bali/bali_cover.jpg",
      bg_img: "https://res.klook.com/image/upload/q_85/c_fill,w_750/v1654586251/blog/wsnqunszlajd5ypjo29l.jpg",
      slider_images: [
        "/cover_images/bali/bali_one.jpg",
        "/cover_images/bali/bali_2.jpg",
        "/cover_images/bali/bali_3.jpg",
        "/cover_images/bali/bali_4.jpg",
        "/cover_images/bali/bali_5.jpg",
        "/cover_images/bali/bali_6.webp",
      ],
      default: "4D3N",
      packages: {
        "4D3N": {
          display_text: "4 Days - 3 Nights",
          cover: "Complimentary 1xParasailing during the water sports activities* | Half Day Water Sportsat Tanjung Benoa on SIC Basis | Full Day Kintamani Tour with Hot Springs with Ubud Coffee Plantation & Ubud Royal Palace Tour on PVT | Ulluwatu Temple Tour followed with Kecak Dance on Private transfers Basis",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 15,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          display_pricing: "BDT 15,000/- ",
          inclusions: [
            "Hotel accommodation in 3-star hotel",
            "All meal mention in above schedule ",
            "All transfer mention in above schedule ",
            "All tour and entrance fees mention as above.",
            "Complimentary 1xParasailing during the water sports activities*",
            "Half Day Water Sportsat Tanjung Benoa on SIC Basis",
            "Full Day Kintamani Tour with Hot Springs with Ubud Coffee Plantation & Ubud Royal Palace Tour on PVT",
            "Ulluwatu Temple Tour followed with Kecak Dance on Private transfers Basis",
            "All Visa fees mentioned in the schedule."
          ],
          exclusions: [
            "Visa Fee – BDT 15,000",
            "Return air ticket – BDT 60,000 - 80,000",
            "Shopping",
            "Transfer, which has been used for self-excursion ",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room",
            "All parking charges to be paid directly by the clients.",
            "If the vehicle is driven outside the ring road such as Nagarkot / Bhaktapur / Dhulikhel / Sarankot / Begnas etc, destination wise charges will be applicable and payable directly in Kathmandu after the vehicle used."
          ],

        }
      }
    },
    {
      id: 2,
      name: "Singapore & Malaysia",
      cover_image: "/cover_images/singapore/sing_1.jpg",
      slider_images: [
        "/cover_images/singapore/sing_2.jpg",
        "/cover_images/singapore/sing_3.jpg",
        "/cover_images/singapore/sing_5.jpg",
        "/cover_images/singapore/sing_6.jpg",
      ],
      default: "5D4N",
      packages: {
        "5D4N": {
          display_text: "5 Days - 4 Nights",
          cover: "Free time for Shopping | Visit Full day tour of Santosa Island. | Visit Kuala Lampur | Visit  Genting",
          include: "Flights |  Hotels | Sightseeing |  Visa ",
          pricing: "BDT 15,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          display_pricing: "BDT 15,000/-",
          inclusions: [
            "Hotel accommodation in 3/4-star hotel ",
            "All meal mention in above schedule ",
            "All transfer mention in above schedule ",
            "All tour and entrance fees mention as above.",
            "Vehicle will be provided based on the itinerary",
            "All Visa fees mentioned in the schedule.",
            "Santosa Island",
            "Kual Lampur",
            "Hotel Accommodation - 3/4 Star",
          ],
          exclusions: [
            "Visa Process fee for both Malaysia and Singapore– BDT 10,000/-",
            "Return air ticket – BDT 35,000-50,000",
            "Shopping",
            "Transfer, which has been used for self-excursion ",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room",
          ],
        }
      }
    },
    {
      id: 3,
      name: "Maldives & Srilanka",
      cover_image: "/cover_images/Maldives & Srilanka/cover_image.jpg",
      slider_images: [
        "/cover_images/Maldives & Srilanka/photo1.jpg",
        "/cover_images/Maldives & Srilanka/photo2.jpg",
        "/cover_images/Maldives & Srilanka/photo3.jpg",
        "/cover_images/Maldives & Srilanka/photo4.jpg",
        "/cover_images/Maldives & Srilanka/photo5.jpg",
      ],
      default: "5D4N_1",
      packages: {
        "5D4N_1": {
          display_text: "5 Days - 4 Nights",
          cover: "hulumale Hotel | Speed boat |  Self-excursion | Colombo hotel | Pinnawala Orphanage",
          include: "Hotel - Transfer-cost - Sightseeing - Vehicle",
          pricing: "BDT 50,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          display_pricing: "BDT 50,000/- ",
          inclusions: [
            "Hotel accommodation in 4-star hotel (Hulumale hotel will advise on availability, Fun Island Resort or Similar for Maldives resort, Galadari hotel or Similar for Colombo)",
            "Breakfast, Lunch and dinner all inclusive",
            "All transfer mention in above schedule ",
            "All tour and entrance fees mention as above.",
          ],
          exclusions: [
            "Visa Process fee – BDT 3500",
            "Return air ticket – BDT 55,000-75,000",
            "Shopping",
            "Transfer, which has been used for self-excursion ",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room.",
            "Any services not mentioned in the inclusions list",
          ],
        }
      }
    },
    {
      id: 4,
      name: "Nepal",
      cover_image: "https://hldak.mmtcdn.com/prod-s3-hld-hpcmsadmin/holidays/images/cities/3816/Nepal.jpg?crop=370:240&downsize=370:240",
      slider_images: [
        "/cover_images/nepal/nepal_1.jpg",
        "/cover_images/nepal/nepal_2.jpg",
        "/cover_images/nepal/nepal_3.jpg",
        "/cover_images/nepal/nepal_4.jpg",
        "/cover_images/nepal/nepal_5.jpg",
        "/cover_images/nepal/nepal_6.jpg",
        "/cover_images/nepal/nepal_7.jpg",
        "/cover_images/nepal/nepal_8.jpg",
      ],
      default: "4D3N_1",
      packages: {
        "4D3N_1": {
          display_text: "4 Days - 3 Nights",
          cover: "Kathmandu | Nagarkot | Patan Durbar Square | Chitwan National Park | Davis Falls",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 25,000/-",
          display_pricing: "BDT 25,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Breakfast, Lunch and dinner all inclusive",
            "Hotel accommodation in 3-star hotel",
            "03 night accommodation in Kathmandu",
            "02 airport transfers by an a/c vehicle exclusively",
            "Sightseeing as per the itinerary in Kathmandu by an a/c vehicle exclusively",
            "Excursion trip to Nagarkot by an A/c vehicle exclusively",
          ],
          exclusions: [
            "Services other than specified.",
            "Entrance fee at the monumental sites during the sightseeing",
            "An English speaking guide during the sightseeing as per above itinerary",
            "Vehicle on disposal till 08 pm on Day 02 only ",
            "Presently applicable taxes.",
            "Any tips to drivers, hotel staff, any meals apart from the ones mentioned above.",
          ],
        }
      }
    },
    {
      id: 6,
      name: "Dubai",
      cover_image: "/cover_images/dubai/dubai_1.jpg",
      slider_images: [
        "/cover_images/dubai/dubai_1.jpg",
        "/cover_images/dubai/dubai_2.jpg",
        "/cover_images/dubai/dubai_3.jpg",
        "/cover_images/dubai/dubai_4.jpg",
        "/cover_images/dubai/dubai_5.jpg",
        "/cover_images/dubai/dubai_6.jpg",
      ],
      default: "6D5N_1",
      packages: {
        "6D5N_1": {
          display_text: "6 Days - 5 Nights",
          cover: "Desert Safari | Burj Khalifa (148th Floor) | Dhow Cruise |",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 40,000/-",
          display_pricing: "BDT 40,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          overview: "",
          inclusions: [
            "Hotel accommodation in 3/4-star hotel",
            "All meal mention in above schedule ",
            "All transfer mention in above schedule ",
            "All tour and entrance fees mention as above.",
            "All tours and transfer pvt basis and including vat."
          ],
          exclusions: [
            "Visa Process fee – BDT 10,000-20,000",
            "Return air ticket – BDT 40,000 – 80,000/-",
            "Shopping",
            "Transfer, which has been used for self-excursion ",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room",
          ],
        }
      }
    },
    {
      id: 7,
      name: "India (Agra,Delhi,Jaipur)",
      cover_image: "/cover_images/india_jaipur_agra_delhi/cover.jpg",
      slider_images: [
        "/cover_images/india_jaipur_agra_delhi/india_4.jpg",
        "/cover_images/india_jaipur_agra_delhi/india_2.jpg",
        "/cover_images/india_jaipur_agra_delhi/india_3.jpg",
        "/cover_images/india_jaipur_agra_delhi/india_5.jpg",
        "/cover_images/india_jaipur_agra_delhi/india_6.jpg",
        "/cover_images/india_jaipur_agra_delhi/india_7.jpg",
      ],
      default: "example",
      packages: {
        "example": {
          display_text: "8 Days - 7 Nights",
          cover: "Jaipur | Ajmer | Agra | New Delhi",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT /-",
          display_pricing: "BDT 40,000 - 50,000/-(APPROX) ",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          overview: "",
          inclusions: [
            "All the tour mention above.",
            "All car services as mention above.",
            "All hotel as mention above.",
            "Breakfast.",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Per person Air Ticket cost: BDT/- (APPROX)",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card",
          ],
        }
      }
    },
    {
      id: 10,
      name: "Bangkok & Pattaya",
      cover_image: "/cover_images/bangkok_pattaya/b&p_cover.jpg",
      slider_images: [
        "/cover_images/bangkok_pattaya/b&p_1.webp",
        "/cover_images/bangkok_pattaya/b&p_2.jpg",
        "/cover_images/bangkok_pattaya/b&p_3.jpg",
        "/cover_images/bangkok_pattaya/b&p_4.jpg",
        "/cover_images/bangkok_pattaya/b&p_5.jpg",
      ],
      default: "5D4N",
      packages: {
        "5D4N": {
          display_text: "5 Days - 4 Nights",
          cover: "bangkok | bangkok safari | Pattaya | free day in bangkok",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 22,500/-",
          display_pricing: "BDT 22,500/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          overview: "",
          inclusions: [
            "Hotel accommodation in 4-star hotel",
            "All meal mention in above schedule ",
            "All transfer mention in above schedule ",
            "All tour and entrance fees mention as above.",
          ],
          exclusions: [
            "Visa Fee – BDT 5,500",
            "Return air ticket – BDT 30,000-50,000",
            "Shopping",
            "Transfer, which has been used for self-excursion ",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room",
          ],
        }
      }
    },
    {
      id: 11,
      name: "Kashmir",
      cover_image: "/cover_images/kashmir/kashmir_cover.jpg",
      slider_images: [
        "/cover_images/kashmir/kashmir_1.jpg",
        "/cover_images/kashmir/kashmir_2.jpg",
        "/cover_images/kashmir/kashmir_3.jpg",
        "/cover_images/kashmir/kashmir_4.jpg",
        "/cover_images/kashmir/kashmir_5.jpg",
        "/cover_images/kashmir/kashmir_6.jpg",
        "/cover_images/kashmir/kashmir_7.jpg",
      ],
      default: "7D6N",
      packages: {
        "7D6N": {
          display_text: "7 Days - 6 Nights",
          cover: "Pahalgam | Gulmarg | Sonmarg | Srinagar",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT /-",
          display_pricing: "BDT /-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Explore Pahalgam",
            "Explore Sonmarg",
            "Explore Srinagar",
            "Explore Gulmarg",
            "All the tour mention above.",
            "All car services as mention above.",
            "All hotel as mention above.",
            "Breakfast.",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
        }
      }
    },
    {
      id: 12,
      name: "Kuala Lampur",
      cover_image: "/cover_images/kuala_lampur/kuala_lampur_cover.jpg",
      slider_images: [
        "/cover_images/kuala_lampur/kuala_lampur_1.jpg",
        "/cover_images/kuala_lampur/kuala_lampur_2.jpg",
        "/cover_images/kuala_lampur/kuala_lampur_3.jpg",
        "/cover_images/kuala_lampur/kuala_lampur_4.jpg",
      ],
      default: "4D3N",
      packages: {
        "4D3N": {
          display_text: "4 Days - 3 Nights",
          cover: "Kuala Lampur Package",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 25,000/-",
          display_pricing: "BDT 25,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Hotel accommodation in 4-star hotel (Federal or Similar)",
            "All meal mention in above schedule ",
            "All transfer mention in above schedule ",
            "All tour and entrance fees mention as above.",
          ],
          exclusions: [
            "Visa Process fee – BDT 6000",
            "Return air ticket – BDT 40000-50000",
            "Shopping",
            "Transfer, which has been used for self-excursion ",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room",
          ],
          itinery: [
            {
              title: "Day : 1 ",
              itinery_img: "/cover_images/kuala_lampur/kuala_lampur_1.jpg",
              description: "Depart from Dhaka, Arrival in Kuala Lampur, After arrival transfer to hotel by private transfer. Rest day free on own. Dinner at local restaurant."
            },
            {
              title: "Day : 2 ",
              itinery_img: "/cover_images/kuala_lampur/kuala_lampur_2.jpg",
              description: "Day tour to Genting Island with lunch. After tour return hotel, fresh time, Dinner at local restaurant."
            },
            {
              title: "Day : 3 ",
              itinery_img: "/cover_images/kuala_lampur/kuala_lampur_3.jpg",
              description: "Free day on own for shopping and Self-Excursion. Lunch and Dinner at local restaurant"
            },
            {
              title: "Day : 4 ",
              itinery_img: "/cover_images/kuala_lampur/kuala_lampur_4.jpg",
              description: "Early morning breakfast, check out from hotel, transfer to Airport, Depart for Dhaka."
            },
          ]
        }
      }
    },
    {
      id: 5,
      name: "Bhutan",
      cover_image: "/cover_images/bhutan/bhutan_2.jpg",
      slider_images: [
        "/cover_images/bhutan/bhutan_1.jpg",
        "/cover_images/bhutan/bhutan_cover.jpg",
        "/cover_images/bhutan/bhutan_3.jpg",
        "/cover_images/bhutan/bhutan_4.jpg",
        "/cover_images/bhutan/bhutan_5.jpg",
      ],
      default: "5D4N_1",
      packages: {
        "5D4N_1": {
          display_text: "5 Days - 4 Nights",
          cover: "Buddha Dordenma statue | Folk Heritage Museum | Buddha viewpoint | National Library of Bhutan | Bhutan Textile Museum | Changangkha Lhakhang | Tashichho Dzong | National Memorial Chorten | National Institute for Zorig Chusum | National Museum of Bhutan | Rinpung Dzong | Taktshang Monastery | Paro Taktsang ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 47,000/-",
          display_pricing: "BDT 47,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "DAC-PBH-DAC Air Ticket (Economy class).",
            "Accommodation ",
            "Airport to Hotel Pick-up and drop.",
            "Breakfast. Lunch. Dinner",
            "Everyday Sight seeing",
            "Driver cum guide."
          ],
          exclusions: [
            "Shopping",
            "Transfer, which has been used for self-excursion ",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room",
          ],
        }
      }
    },
    {
      id: 8,
      name: "Bangkok",
      cover_image: "/cover_images/bangkok/bangkok_cover.jpg",
      slider_images: [
        "/cover_images/bangkok/bangkok_1.jpg",
        "/cover_images/bangkok/bangkok_2.jpg",
        "/cover_images/bangkok/bangkok_3.jpg",
        "/cover_images/bangkok/bangkok_4.jpg",
        "/cover_images/bangkok/bangkok_5.jpg",
      ],
      default: "5D4N",
      packages: {
        "5D4N": {
          display_text: "5 Days - 4 Nights",
          cover: "Bangkok City | Safari World",
          include: "Hotel | Transfer-cost | Sightseeing | Taxes | Vehicle",
          pricing: "BDT 17,000/-",
          display_pricing: "BDT 17,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          overview: "",
          inclusions: [
            "Visa Fee – BDT 5,500",
            "Return air ticket – BDT 30,000-50,000",
            "Hotel accommodation in 4-star hotel",
            "Breakfast, Lunch and dinner all inclusive",
            "All transfer mention in above schedule ",
            "All tour and entrance fees mention as above."
          ],
          exclusions: [
            "Visa Process fee",
            "Return air ticket",
            "Shopping",
            "Transfer, which has been used for self-excursion ",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room.",
            "Air ticket cannot be changed or cancel (Group Ticket)",
          ],
          itinery: [
            {
              title: "Day : 1 ",
              itinery_img: "/cover_images/bangkok/bangkok_1.jpg",
              description: "Depart from Dhaka, Arrival in Bangkok, Transfer to hotel by Bus, After arrival lunch at Hotel, Rest time free on own.  Dinner at local restaurant."
            },
            {
              title: "Day : 2 ",
              itinery_img: "/cover_images/bangkok/bangkok_2.jpg",
              description: "Full day tour of Safari World with Lunch, After tour transfer to hotel, rest time free on own. Dinner at local restaurant."
            },
            {
              title: "Day : 3 ",
              itinery_img: "/cover_images/bangkok/bangkok_3.jpg",
              description: "Free day on own, for self-excursion and shopping lunch and dinner will be at local restaurant."
            },
            {
              title: "Day : 4 ",
              itinery_img: "/cover_images/bangkok/bangkok_4.jpg",
              description: "Half day city tour of Bangkok, Rest day free on own."
            },
            {
              title: "Day : 5 ",
              itinery_img: "/cover_images/bangkok/bangkok_5.jpg",
              description: "Early morning breakfast, Checkout from hotel, Depart for Dhaka."
            },
          ]
        }
      }
    },
    {
      id: 14,
      name: "Turkey",
      cover_image: "/cover_images/turkey/turkey_cover.jpg",
      slider_images: [
        "/cover_images/turkey/turkey_1.jpg",
        "/cover_images/turkey/turkey_2.jpg",
        "/cover_images/turkey/turkey_3.jpg",
        "/cover_images/turkey/turkey_4.jpg",
        "/cover_images/turkey/turkey_5.jpg",
      ],
      default: "5D4N",
      packages: {
        "5D4N": {
          display_text: "5 Days - 4 Nights",
          cover: "Istanbul | Roman Heritage & Ottoman Marvels | Bursa | Bosphorus cruise tour",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 53,000/-",
          display_pricing: "BDT 53,000/-",
          pricing_dec: "Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice.",
          overview: "",
          inclusions: [
            "Hotel accommodation in 3/4-star hotel ",
            "All meal mention in above schedule ",
            "All transfer mention in above schedule ",
            "All tour and entrance fees mention as above.",
          ],
          exclusions: [
            "Visa Process fee – BDT 10,000-15,000",
            "Return air ticket – BDT 60,000 – 90,000/-",
            "Shopping",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room.",
            "Air ticket cannot be changed or cancel (Group Ticket)",
          ],
          itinery: [
            {
              title: "Day : 1 ",
              itinery_img: "/cover_images/turkey/turkey_1.jpg",
              description: "Arrival Istanbul, Transfer to hotel by private transfer, after check in rest day free for self-excursion."
            },
            {
              title: "Day : 2 ",
              itinery_img: "/cover_images/turkey/turkey_2.jpg",
              description: "Full Day Tour of Roman Heritage & Ottoman Marvels Tour with Lunch and Private Transfer."
            },
            {
              title: "Day : 3 ",
              itinery_img: "/cover_images/turkey/turkey_3.jpg",
              description: "Day long Bursa tour with Cable Car & lunch with private transfer."
            },
            {
              title: "Day : 4 ",
              itinery_img: "/cover_images/turkey/turkey_4.jpg",
              description: "Half day city tour of Istanbul city with lunch. After lunch depart for Bosphorus dinner cruise tour."
            },
            {
              title: "Day : 5 ",
              itinery_img: "/cover_images/turkey/turkey_5.jpg",
              description: "Check out from hotel, transfer to airport by private car. Depart for Dhaka."
            },
          ]
        }
      }
    },
    {
      id: 16,
      name: "Singapore Trip 2",
      cover_image: "/cover_images/singapore_2/singapore_cover.jpg",
      slider_images: [
        "/cover_images/singapore_2/singapore_1.jpg",
        "/cover_images/singapore_2/singapore_2.jpg",
        "/cover_images/singapore_2/singapore_3.jpg",
        "/cover_images/singapore_2/singapore_4.jpg",
      ],
      default: "4D3N",
      packages: {
        "4D3N": {
          display_text: "4 Days - 3 Nights",
          cover: "Singapore | Santosa Island | Garden by The Bay | ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 27,500/-",
          display_pricing: "BDT 27,500/-",
          pricing_dec: "Per person tour package including hotel, Land Package only, Air Ticket & Visa Cost Separate.",
          overview: "",
          inclusions: [
            "Hotel accommodation in 3/4-star hotel ",
            "All meal mention in above schedule ",
            "All transfer mention in above schedule ",
            "All tour and entrance fees mention as above.",
          ],
          exclusions: [
            "Visa Process fee – BDT 5000",
            "Return air ticket – BDT 30,000 – 50,000/-",
            "Shopping",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room.",
            "Air ticket cannot be changed or cancel (Group Ticket)",
          ],
          itinery: [
            {
              title: "Day : 1 Singapore",
              itinery_img: "/cover_images/singapore_2/singapore_1.jpg",
              description: "Arrival Singapore, transfer to hotel by private car. "
            },
            {
              title: "Day : 2 Singapore/Santosa",
              itinery_img: "/cover_images/singapore_2/singapore_2.jpg",
              description: "Full day tour of Santosa Island."
            },
            {
              title: "Day : 3 Singapore/ garden by the bay",
              itinery_img: "/cover_images/singapore_2/singapore_3.jpg",
              description: "Half day city tour of Singapore, evening tour of garden by the bay."
            },
            {
              title: "Day : 4 Singapore",
              itinery_img: "/cover_images/singapore_2/singapore_4.jpg",
              description: "Check out from hotel, transfer to airport."
            },
          ]
        }
      }
    },
    {
      id: 654,
      name: "Switzerland Trip",
      cover_image: "/cover_images/Switzerland/Switzerland_cover_image.jpg",
      slider_images: [
        "/cover_images/Switzerland/photo1.jpg",
        "/cover_images/Switzerland/photo2.jpg",
        "/cover_images/Switzerland/photo3.jpg",
        "/cover_images/Switzerland/photo4.jpg",
      ],
      default: "7D6N",
      packages: {
        "7D6N": {
          display_text: "7 Days - 6 Nights",
          cover: "Lucerne | Zurich | Swiss Pass | Mt.Titlis | Rhine Falls",
          include: "Hotel - Transfer-cost - Sightseeing - Passes - Vehicle",
          pricing: "BDT 1,49,500/-",
          display_pricing: "BDT 1,49,500/-",
          pricing_dec: "Per person tour package including hotel, Land Package only, Air Ticket & Visa Cost Separate.",
          overview: "",
          inclusions: [
            "Nights accommodation in Lucerne Ibis Styles Lucerne or similar",
            "Nights accommodation in Zurich - Novotel Zurich Airport Messe or similarDaily Breakfast",
            "2nd Class 06 Days Swiss Pass",
            "Excursion To Mt. Titlis with Ice Flyer",
            "Lake Lucerne Cruise with Swiss Pass",
            "Visit Rhine Falls in Zurich with Swiss Pass (Boat Ride Optional) Private departure transfer in Zurich"
          ],
          exclusions: [
            "Visa Process fee – BDT 6000",
            "Shopping",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room.",
            "Air ticket cannot be changed or cancel (Group Ticket)",
          ],
          itinery: [
            {
              title: "Day : 1 Singapore",
              itinery_img: "/cover_images/singapore_2/singapore_1.jpg",
              description: "Arrival Singapore, transfer to hotel by private car. "
            },
            {
              title: "Day : 2 Singapore/Santosa",
              itinery_img: "/cover_images/singapore_2/singapore_2.jpg",
              description: "Full day tour of Santosa Island."
            },
            {
              title: "Day : 3 Singapore/ garden by the bay",
              itinery_img: "/cover_images/singapore_2/singapore_3.jpg",
              description: "Half day city tour of Singapore, evening tour of garden by the bay."
            },
            {
              title: "Day : 4 Singapore",
              itinery_img: "/cover_images/singapore_2/singapore_4.jpg",
              description: "Check out from hotel, transfer to airport."
            },
          ]
        }
      }
    },
    {
      id: 17,
      name: "Explore Switzerland",
      cover_image: "/cover_images/Explore_Switzerland/Explore_Switzerland_cover_image.jpg",
      slider_images: [
        "/cover_images/Explore_Switzerland/photo1.jpg",
        "/cover_images/Explore_Switzerland/photo2.jpg",
        "/cover_images/Explore_Switzerland/photo3.jpg",
        "/cover_images/Explore_Switzerland/photo4.jpg",
        "/cover_images/Explore_Switzerland/photo5.jpg",
        "/cover_images/Explore_Switzerland/photo6.jpg",
      ],
      default: "8D7N",
      packages: {
        "8D7N": {
          display_text: "8 Days - 7 Nights",
          cover: "Lucerne | Zurich | Swiss Pass | Mt.Titlis | Rhine Falls | Jungfraujoch Top of Europe",
          include: "Hotel - Transfer-cost - Sightseeing - Passes - Vehicle - Daily Breakfast",
          pricing: "BDT 1,68,350/-",
          display_pricing: "BDT 1,68,350/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          overview: "",
          inclusions: [
            "Nights accommodation in Lucerne Ibis Styles Lucerne or similar",
            "Nights accommodation in Interlaken - Hotel Chalet Swiss or similar",
            "Nights accommodation in Zurich - Novotel Zurich Airport Messe or similar",
            "2nd Class 08 Days Swiss Pass",
            "Excursion To Mt. Titlis with Ice flyer",
            "Lake Brienz Cruise with Swiss Pass",
            "Excursion to Jungfraujoch Top of Europe",
            "Visit Rhine Falls in Zurich with Swiss Pass (Boat Ride Optional)",
          ],
          exclusions: [
            "Visa Process fee – BDT 6000",
            "Shopping",
            "Separate food cost (not mention in the schedule)",
            "Any kind of Alcohol",
            "Any room services which is not included in the booked room.",
            "Air ticket cannot be changed or cancel (Group Ticket)",
          ],
          itinery: [
            {
              title: "Day : 1 Singapore",
              itinery_img: "/cover_images/singapore_2/singapore_1.jpg",
              description: "Arrival Singapore, transfer to hotel by private car. "
            },
            {
              title: "Day : 2 Singapore/Santosa",
              itinery_img: "/cover_images/singapore_2/singapore_2.jpg",
              description: "Full day tour of Santosa Island."
            },
            {
              title: "Day : 3 Singapore/ garden by the bay",
              itinery_img: "/cover_images/singapore_2/singapore_3.jpg",
              description: "Half day city tour of Singapore, evening tour of garden by the bay."
            },
            {
              title: "Day : 4 Singapore",
              itinery_img: "/cover_images/singapore_2/singapore_4.jpg",
              description: "Check out from hotel, transfer to airport."
            },
          ]
        }
      }
    },
    {
      id: 18,
      name: "Ultimate Switzerland Trip",
      cover_image: "/cover_images/Ultimate_Switzerland_Trip/ulti_Switzerland_cover_image.jpg",
      slider_images: [
        "/cover_images/Ultimate_Switzerland_Trip/photo1.jpg",
        "/cover_images/Ultimate_Switzerland_Trip/photo2.jpg",
        "/cover_images/Ultimate_Switzerland_Trip/photo3.jpg",
        "/cover_images/Ultimate_Switzerland_Trip/photo4.jpg",
        "/cover_images/Ultimate_Switzerland_Trip/photo5.jpg",
        "/cover_images/Ultimate_Switzerland_Trip/photo6.jpg",
        "/cover_images/Ultimate_Switzerland_Trip/photo7.jpg",
        "/cover_images/Ultimate_Switzerland_Trip/photo8.jpg",
      ],
      default: "10D9N",
      packages: {
        "10D9N": {
          display_text: "10 Days - 9 Nights",
          cover: "Pahalgam | Gulmarg | Sonmarg | Srinagar",
          include: "Lucerne | Zurich | Swiss Pass | Mt.Titlis | Rhine Falls | Jungfraujoch Top of Europe | Golden Pass Line",
          pricing: "BDT 2,02,800/-",
          display_pricing: "BDT 2,02,800/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Lucerne Ibis Styles Lucerne or similar",
            "Nights accommodation in Interlaken - Hotel Chalet Swiss or similar",
            "Nights accommodation in Montreux - Hôtel Villa Toscane or similar",
            "Night accommodation in Zurich - Novotel Zurich Airport Messe or similar",
            "Exploring local hidden gems",
            "2nd Class Golden Pass Line",
            "Lake Lucerne Cruise with Swiss Pass",
            "Excursion To Mt. Titlis with Ice flyer",
            "Excursion to Jungfraujoch Top of Europe",
            "Visit Rhine Falls in Zurich with Swiss Pass (Boat Ride Optional)",
            "Private departure transfer in Zurich",
            "Reservation Daily Breakfast",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 6000",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 19,
      name: "France",
      cover_image: "/cover_images/France/France_cover_image.jpg",
      slider_images: [
        "/cover_images/France/photo1.jpg",
        "/cover_images/France/photo2.jpg",
        "/cover_images/France/photo3.jpg",
        "/cover_images/France/photo4.jpg",
        "/cover_images/France/photo5.jpg",
      ],
      default: "8D7N",
      packages: {
        "8D7N": {
          display_text: "8 Days - 7 Nights",
          cover: "Paris  | Nice - Ibis  | Seine River Cruise  | Eiffel Tower | Disneyland | Monte Carlo | Monaco ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle - Breakfast",
          pricing: "BDT 1,45,600/-",
          display_pricing: "BDT 1,45,600/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate.",
          inclusions: [
            "Nights accommodation in Paris - Campanille Hotel Bercy Village or Similar",
            "Night accommodation in Nice - Ibis Nice Centre Notre Dame or Similar",
            "2nd Class Train Ticket from Paris - Nice",
            "Paris Hop on & Hop Off 24 Hours City Tour",
            "Seine River Cruise Ticket",
            "Skip the Line: Eiffel Tower 2nd Level Tickets",
            "Day Trip to Disneyland - 01 Day 01 Park with Shared Coach Transportation",
            "Nice Hop on & Hop Off 24 Hours City Tour",
            "Day Trip to Eze, Monaco & Monte Carlo from Nice",
            "Private transfer from Airport - Hotel - Train Station in Paris",
            "Private transfer from Train Station - Hotel - Airport in Nice",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 6000/-",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 20,
      name: "Paris & Switzerland",
      cover_image: "/cover_images/Paris & Switzerland/paris&swiss_cover_Image.jpg",
      slider_images: [
        "/cover_images/Paris & Switzerland/photo1.jpg",
        "/cover_images/Paris & Switzerland/photo4.jpg",
        "/cover_images/Paris & Switzerland/photo5.jpg",
        "/cover_images/Paris & Switzerland/photo6.jpg",
        "/cover_images/Paris & Switzerland/photo7.jpg",
      ],
      default: "7D6N",
      packages: {
        "7D6N": {
          display_text: "7 Days - 6 Nights",
          cover: "Paris  | Nice - Ibis  | Seine River Cruise  | Eiffel Tower | Disneyland | Lucerne | Zurich | Swiss Pass | Mt.Titlis | Rhine Falls | Lindt Home of Chocolate ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,72,250/-",
          display_pricing: "BDT 1,72,250/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Night accommodation in Paris - Campanille Hotel Bercy Village or Similar",
            "Night accommodation in Lucerne - Ibis Styles Luzern or Similar",
            "Night accommdation in Zurich - Novotel Zurich Airport Messe or Similar",
            "2nd Class Train Ticket from Paris - Basel",
            "Paris Hop on & Hop Off 24 Hours City Tour Seine River Cruise Ticket ",
            "Eiffel Tower 2nd Level Tickets",
            "Day Trip to Disneyland - 01 Day 01 Park with Shared Coach Transportation",
            "2nd Class 04 Days Consecutive Swiss Pass Lake Lucerne Cruise with Swiss Pass",
            "Excursion To Mt. Titlis with Ice flyer",
            "Visit Rhine Falls with Swiss Pass (Boat Ride Optional)",
            "Visit Lake Zurich, Old Town, Landquart, Shopping Village with Swiss Pass",
            "Lindt Home of Chocolate Tickets",
            "Private transfer from Airport - Hotel - Train Station in Paris",
            "Private transfer from Hotel - Airport in Zurich",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 6000",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 21,
      name: "Amsterdam - Paris - Swiss",
      cover_image: "/cover_images/Amsterdam - Paris - Switzerland/Amsterdam_Paris_Switzerland_ cover_image.jpg",
      slider_images: [
        "/cover_images/Amsterdam - Paris - Switzerland/photo1.jpg",
        "/cover_images/Amsterdam - Paris - Switzerland/photo2.jpg",
        "/cover_images/Amsterdam - Paris - Switzerland/photo3.jpg",
        "/cover_images/Amsterdam - Paris - Switzerland/photo4.jpg",
        "/cover_images/Amsterdam - Paris - Switzerland/photo5.jpg",
        "/cover_images/Amsterdam - Paris - Switzerland/photo6.jpg",
        "/cover_images/Amsterdam - Paris - Switzerland/photo7.jpg",
      ],
      default: "7D6N",
      packages: {
        "7D6N": {
          display_text: "7 Days - 6 Nights",
          cover: "Volendam | Marken & Windmills | Paris  | Nice - Ibis  | Seine River Cruise  | Eiffel Tower | Disneyland | Lucerne | Zurich | Swiss Pass | Mt.Titlis | Rhine Falls | Lindt Home of Chocolate ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 2,65,000/-",
          display_pricing: "BDT 2,65,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "All the tour mention above.",
            "All car services as mention above.",
            "All hotel as mention above.",
            "Breakfast.",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 22,
      name: "Italy",
      cover_image: "/cover_images/Italy/italy_cover_image.jpg",
      slider_images: [
        "/cover_images/Italy/photo1.jpg",
        "/cover_images/Italy/photo2.jpg",
        "/cover_images/Italy/photo3.jpg",
        "/cover_images/Italy/photo4.jpg",
        "/cover_images/Italy/photo5.jpg",
      ],
      default: "8D7N",
      packages: {
        "8D7N": {
          display_text: "8 Days - 7 Nights",
          cover: "Rome | Florence | Venice | Milan",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 2,15,000/-",
          display_pricing: "BDT 2,15,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Rome - Domus Sessoriana or Similar",
            "Night accommodation in Florence - Atlantic Palace or Similar",
            "Nights accommodation in Venice - Al Nuovo Teson Hotel or Similar",
            "Nights accommodation in Milan - Hotel Majestic or Similar",
            "2nd Class Train Ticket from Rome Florence",
            "2nd Class Train Ticket from Florence - Venice",
            "2nd Class Train Ticket from Venice Milan",
            "Rome Hop on & Hop Off 24 Hours City Tour",
            "Skip the Line: Colosseum, Roman Forum & Sistine Chapel Day Trip to Pisa & Leaning Tower from",
            "Florence (Leaning Tower from outside) Venice Canal Gondola Ride",
            "Murano, Burano & Torcello Island Tour from Venice",
            "Milan Hop on & Hop off 24 Hours City Tour",
            "Private transfer from Airport Hotel - Train Station in Rome",
            "Private transfer from Train Station Hotel - Train Station in Florence",
            "Private transfer from Train Station Hotel - Train Station in Venice",
            "Private transfer from Train Station - Hotel Airport, in Milan",
            "All car services as mention above.",
            "All hotel as mention above.",
            "Breakfast.",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 23,
      name: "Explore Italy",
      cover_image: "/cover_images/Explore Italy/expitaly_cover_image.jpg",
      slider_images: [
        "/cover_images/Explore Italy/photo5.jpg",
        "/cover_images/Explore Italy/photo3.jpg",
        "/cover_images/Explore Italy/photo4.jpg",
        "/cover_images/Explore Italy/photo6.jpg",
        "/cover_images/Explore Italy/photo7.jpg",
        "/cover_images/Explore Italy/photo1.jpg",
        "/cover_images/Explore Italy/photo2.jpg",
      ],
      default: "10D9N",
      packages: {
        "10D9N": {
          display_text: "10 Days - 9 Nights",
          cover: "Rome | Florence | Venice | Milan | Pisa & Leaning Tower from Florence | Venice Canal Gondola",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 2,76,900/-",
          display_pricing: "BDT 2,76,900/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Rome - Domus Sessoriana or Similar",
            "Night accommodation in Florence - Atlantic Palace or Similar",
            "Nights accommodation in Venice - Al Nuovo Teson Hotel or Similar",
            "Nights accommodation in Milan - Hotel Majestic or Similar",
            "2nd Class Train Ticket from Rome Florence",
            "2nd Class Train Ticket from Florence - Venice",
            "2nd Class Train Ticket from Venice Milan",
            "Day Trip to Sorrento, Positano & Amalfi from Naples",
            "Day Trip to Pisa & Leaning Tower from Florence",
            "Rome Hop on & Hop Off 24 Hours City Tour",
            "Skip the Line: Colosseum, Roman Forum & Sistine Chapel Day Trip to Pisa & Leaning Tower from",
            "Florence (Leaning Tower from outside) Venice Canal Gondola",
            "Murano, Burano & Torcello Island Tour from Venice",
            "Milan Hop on & Hop off 24 Hours City Tour",
            "Private transfer from Airport Hotel - Train Station in Rome",
            "Private transfer from Train Station Hotel - Train Station in Florence",
            "Private transfer from Train Station Hotel - Train Station in Venice",
            "Private transfer from Train Station - Hotel Airport, in Milan",
            "All car services as mention above.",
            "All hotel as mention above.",
            "Breakfast.",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 24,
      name: "Brussels - Amsterdam - Germany",
      cover_image: "/cover_images/Brussels - Amsterdam - Germany/photo3.jpg",
      slider_images: [
        "/cover_images/Brussels - Amsterdam - Germany/photo1.jpg",
        "/cover_images/Brussels - Amsterdam - Germany/photo2.jpg",
        "/cover_images/Brussels - Amsterdam - Germany/photo3.jpg",
        "/cover_images/Brussels - Amsterdam - Germany/photo4.jpg",
        "/cover_images/Brussels - Amsterdam - Germany/photo5.jpg",
        "/cover_images/Brussels - Amsterdam - Germany/photo6.jpg",
      ],
      default: "8D7N",
      packages: {
        "8D7N": {
          display_text: "8 Days - 7 Nights",
          cover: "Brussels | Amsterdam | Frankfurt | Bruges & Ghent | Amsterdam Canal Cruise | Volendam",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,82,000/-",
          display_pricing: "BDT 1,82,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Brussels - Bedford Hotel & Congress Centre or Similar",
            "Nights accommodation in Amsterdam - Avenue Hotel Amsterdam or Similar",
            "Nights accommodation in Frankfurt - Premier Inn Frankfurt City Centre hotel or SimilarDaily Breakfast",
            "2nd Class Train Ticket from Brussels - Amsterdam",
            "2nd Class Train Ticket from Amsterdam Frankfurt Brussels Hop on & Hop Off 24 Hours City Tour Day Ttrip to Bruges & Ghent from Brussels Amsterdam Canal Cruise Tickets Day Trip to Volendam, Marken & Windmills from Amsterdam",
            "Frankfurt Hop on & Hop Off 24 Hours City Tour",
            "Night accommodation in Zurich - Novotel Zurich Airport Messe or similar",
            "Private transfer from Airport - Hotel - Train Station in Brussels",
            "2nd Class Golden Pass Line",
            "Private transfer from Train Station Hotel - Train Station in Amsterdam",
            "Private transfer from Train Station Hotel Airport in Frankfurt",
            "All the tour mention above.",
            "All car services as mention above.",
            "All hotel as mention above.",
            "Breakfast Included",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 25,
      name: "Pragne - Vienna - Busdapest",
      cover_image: "/cover_images/Pragne - Vienna - Busdapest/photo3.jpg",
      slider_images: [
        "/cover_images/Pragne - Vienna - Busdapest/photo1.jpg",
        "/cover_images/Pragne - Vienna - Busdapest/photo2.jpg",
        "/cover_images/Pragne - Vienna - Busdapest/photo3.jpg",
        "/cover_images/Pragne - Vienna - Busdapest/photo4.jpg",
        "/cover_images/Pragne - Vienna - Busdapest/photo5.jpg",
        "/cover_images/Pragne - Vienna - Busdapest/photo6.jpg",
      ],
      default: "8D7N",
      packages: {
        "8D7N": {
          display_text: "8 Days - 7 Nights",
          cover: "Prague | Vienna | Budapest | Swiss Pass | Prague Hop on & Hop Off | Vienna Hop on & Hop Off",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,82,000/-",
          display_pricing: "BDT 1,82,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Prague - Atlantic or Similar",
            "Nights accommodation in Vienna - Arion Cityhotel Vienna or Similar",
            "Nights accommodation in Budapest - Corvin Center Suites or Similar",
            "Daily Breakfast",
            "2nd Class Train Ticket from Prague - Vienna",
            "2nd Class Train Ticket from Vienna Budapest",
            "Prague Hop on & Hop Off 24 Hours City Tour",
            "Vienna Hop on & Hop Off 24 Hours City Tour",
            "Budapest Hop on & Hop Off 24 Hours City Tour",
            "Private transfer from Airport - Hotel - Train Station in Prague Private transfer from Airport Hotel Train Station in Brussels",
            "Private transfer from Train Station Hotel - Train Station in Vienna",
            "Private transfer from Train Station Hotel - Airport in Budapest",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 26,
      name: "Explore Pragne - Vienna - Busdapest",
      cover_image: "/cover_images/Explore Pragne - Vienna - Busdapest/prague_cover_image.jpg",
      slider_images: [
        "/cover_images/Explore Pragne - Vienna - Busdapest/photo1.jpg",
        "/cover_images/Explore Pragne - Vienna - Busdapest/photo2.jpg",
        "/cover_images/Explore Pragne - Vienna - Busdapest/photo3.jpg",
        "/cover_images/Explore Pragne - Vienna - Busdapest/photo4.jpg",
        "/cover_images/Explore Pragne - Vienna - Busdapest/photo5.jpg",
      ],
      default: "10D9N",
      packages: {
        "10D9N": {
          display_text: "10 Days - 9 Nights",
          cover: "Prague | Vienna | Budapest | Swiss Pass | Prague Hop on & Hop Off | Vienna Hop on & Hop Off | Budapest Hop on & Hop Off | Innsbruck | Hilton Garden Inn Innsbruck Tivoli | Salzburg ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,87,200/-",
          display_pricing: "BDT 1,87,200/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Prague - Atlantic or Similar",
            "Nights accommodation in Vienna - Arion Cityhotel Vienna or Similar",
            "Night accommodation in Innsbruck - Hilton Garden Inn Innsbruck Tivoli or Similar",
            "Nights accommodation in Budapest - Corvin Center Suites or Similar",
            "Daily Breakfast",
            "2nd Class Train Ticket from Prague - Vienna",
            "2nd Class Train Ticket from Vienna Budapest",
            "2nd Class Train Ticket from Innsbruck - Salzburg",
            "2nd Class Train Ticket from Salzburg - Budapest",
            "Prague Hop on & Hop Off 24 Hours City Tour",
            "Vienna Hop on & Hop Off 24 Hours City Tour",
            "Budapest Hop on & Hop Off 24 Hours City Tour",
            "Private transfer from Airport - Hotel - Train Station in Prague Private transfer from Airport Hotel Train Station in Brussels",
            "Private transfer from Train Station Hotel - Train Station in Vienna",
            "Private transfer from Train Station Hotel - Airport in Budapest",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 27,
      name: "Austria & Swiss",
      cover_image: "/cover_images/kashmir/kashmir_cover.jpg",
      slider_images: [
        "/cover_images/Explore Italy/photo1.jpg",
        "/cover_images/Explore Italy/photo2.jpg",
        "/cover_images/Explore Italy/photo3.jpg",
        "/cover_images/Explore Italy/photo4.jpg",
        "/cover_images/Explore Italy/photo5.jpg",
        "/cover_images/Explore Italy/photo6.jpg",
        "/cover_images/Explore Italy/photo7.jpg",
      ],
      default: "10D9N",
      packages: {
        "10D9N": {
          display_text: "10 Days - 9 Nights",
          cover: "",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 2,29,450/-",
          display_pricing: "BDT 2,29,450/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Night accommodation in Vienna Hotel Caroline or Similar",
            "Night accommodation in Salzburg Cityhotel Trumer Stube or Similar",
            "Night accommodation in Innsbruck - Hilton Garden Inn Innsbruck Tivoli or Similar. Exploring local hidden gems",
            "Night accommodation in Lucerne - Ibis Styles Luzern or Similar",
            "Night accommdation in Zurich Novotel Zurich Airport Messe or Similar",
            "2nd Class Train Ticket from Vienna Salzburg",
            "2nd Class Train Ticket from Salzburg Innsbruck",
            "2nd Class Train Ticket from Innsbruck Zurich",
            "Vienna Hop on & Hop Off 24 Hours City Tour",
            "Salzburg Hop on & Hop Off 24 Hours City Tour",
            "Swarovski Crystal World Tickets",
            "02nd Class 04 Days Consecutive Swiss Pass",
            "Lake Lucerne Cruise with Swiss Pass",
            "Excursion to Mt. Titlis with Ice Flyer",
            "Visit Rhine Falls with Swiss Pass (Boat Ride Optional)",
            "Visit Lake Zurich, Old Town, Landquart Shopping Village with Swiss Pass",
            "Lindt Home of Chocolate Tickets",
            "Private transfer from Airport Hotel Train Station in Vienna",
            "Private transfer from Train Station Hotel - Train Station in Salzburg",
            "Private transfer from Train Station Hotel Train Station in Innsbruck",
            "Private transfer from Airport - Hotel in Zurich",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 28,
      name: "Iceland",
      cover_image: "/cover_images/Iceland/iceland_cover_image.jpg",
      slider_images: [
        "/cover_images/Iceland/photo1.jpg",
        "/cover_images/Iceland/photo2.jpg",
        "/cover_images/Iceland/photo3.jpg",
        "/cover_images/Iceland/photo4.jpg",
        "/cover_images/Iceland/photo5.jpg",
        "/cover_images/Iceland/photo6.jpg",
      ],
      default: "7D6N",
      packages: {
        "7D6N": {
          display_text: "7 Days - 6 Nights",
          cover: "Reykajvik | Northern Lights |  Golden Circle from Reykjavik | South Coast from Reykjavik | Whale Watching",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,75,500/-",
          display_pricing: "BDT 1,75,500/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Night accommodation in Reykajvik - Hotel Holt or Similar",
            "Daily Breakfast",
            "Private transfer from Airport - Hotel - Airport in Reykjavik",
            "Northern Lights Tour",
            "Day Trip to Golden Circle from Reykjavik",
            "Day Trip to South Coast from Reykjavik",
            "Whale Watching Tour",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 29,
      name: "Explore Turkey",
      cover_image: "/cover_images/Explore Turkey/exturkey_cover_image.jpg",
      slider_images: [
        "/cover_images/Explore Turkey/photo4.jpg",
        "/cover_images/Explore Turkey/photo5.jpg",
        "/cover_images/Explore Turkey/photo6.jpg",
        "/cover_images/Explore Turkey/photo7.jpg",
        "/cover_images/Explore Turkey/photo8.jpg",
      ],
      default: "9D8N",
      packages: {
        "9D8N": {
          display_text: "9 Days - 8 Nights",
          cover: "Istanbul  | Cappadocia  | Antalya  | Bosphorus Cruise Istanbul | Cappadocia Green Tour | Antalya City Tour with Cable Car & Waterfalls | Pamukkale & Heirapolis ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,53,400/-",
          display_pricing: "BDT 1,53,400/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Istanbul - Endless Suites Taksim or Similar",
            "Nights accommodation in Cappadocia - Acer Cave Hotel or Similar",
            "Nights accommodation in Antalya Prime Boutique Hotel or Similar ",
            "Night accommodation in Istanbul - Villa Pera Suite Hotel or Similar",
            "Daily Breakfast",
            "Bosphorus Cruise Istanbul",
            "Cappadocia Green Tour",
            "Antalya City Tour with Cable Car & Waterfalls",
            "Pamukkale & Heirapolis Tour from Antalya",
            "Private transfer from Airport - Hotel Airport in Istanbul",
            "Private transfer from Kayseri Airport - Cappadocia Hotel - Kayseri Airport",
            "Private transfer from Airport Hotel Airport in Antalya",
            "Private transfer from Airport - Hotel - Airport in Antalya",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 30,
      name: "Ultimate Turkey",
      cover_image: "/cover_images/Ultimate Turkey/ultiturkey_cover_image.jpg",
      slider_images: [
        "/cover_images/Ultimate Turkey/photo1.jpg",
        "/cover_images/Ultimate Turkey/photo2.jpg",
        "/cover_images/Ultimate Turkey/photo3.jpg",
        "/cover_images/Ultimate Turkey/photo4.jpg",
        "/cover_images/Ultimate Turkey/photo5.jpg",
        "/cover_images/Ultimate Turkey/photo6.jpg",
        "/cover_images/Ultimate Turkey/photo7.jpg",
        "/cover_images/Ultimate Turkey/photo8.jpg",
      ],
      default: "10D9N",
      packages: {
        "10D9N": {
          display_text: "10 Days - 9 Nights",
          cover: "Istanbul  | Cappadocia  | Antalya  | Bosphorus Cruise Istanbul | Cappadocia Green Tour | Antalya City Tour with Cable Car & Waterfalls | Pamukkale & Heirapolis | Kusadasi | Cable Car ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 2,11,900/-",
          display_pricing: "BDT 2,11,900/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Istanbul - Endless Suites Taksim or Similar",
            "Nights accommodation in Cappadocia - Acer Cave Hotel or Similar",
            "Nights accommodation in Antalya Prime Boutique Hotel or Similar ",
            "Night accommodation in Istanbul - Villa Pera Suite Hotel or Similar",
            "Daily Breakfast",
            "Bosphorus Cruise Istanbul",
            "Full Day Istanbul City Tour",
            "Cappadocia Green Tour",
            "Antalya City Tour with Cable Car & Waterfalls",
            "Pamukkale & Heirapolis Tour from Antalya",
            "Private transfer from Airport - Hotel Airport in Istanbul",
            "Private transfer from Kayseri Airport - Cappadocia Hotel - Kayseri Airport",
            "Private transfer from Airport Hotel Airport in Antalya",
            "Private transfer from Airport - Hotel - Airport in Antalya",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 31,
      name: "Greece",
      cover_image: "/cover_images/Greece/Greece_cover_image.jpg",
      slider_images: [
        "/cover_images/Greece/photo1.jpg",
        "/cover_images/Greece/photo2.jpg",
        "/cover_images/Greece/photo3.jpg",
        "/cover_images/Greece/photo4.jpg",
        "/cover_images/Greece/photo5.jpg",
      ],
      default: "8D7N",
      packages: {
        "8D7N": {
          display_text: "8 Days - 7 Nights",
          cover: "Athens | Mykonos | Santorini | Athens",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,79,400/-",
          display_pricing: "BDT 1,79,400/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Athens- Acropolis Select Hotel or Similar",
            "Nights accommodation in Mykonos - Sunrise Beach or Similar",
            "Nights accommodation in Santorini - Rimida Villas or Similar",
            "Night accommodation in Athens- Acropolis Select Hotel or Similar",
            "Daily Breakfast",
            "Ferry from Athens to Mykonos",
            "Ferry from Mykonos to Santorini",
            "Ferry from Santorini to Athens",
            "Athens half-day sightseeing tour with Acropolis Museum",
            "Mykonos Half Day City Tour",
            "Santorini City Tour with Sunset Oia",
            "Private arrival and departure transfer in Athens",
            "Private arrival and departure transfer in Mykonos",
            "Private arrival and departure transfer in Santorini",
            "Private arrival and departure transfer in Athens",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/Greece/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 32,
      name: "Spain",
      cover_image: "/cover_images/Spain/spain_cover_image.jpg",
      slider_images: [
        "/cover_images/Spain/photo1.jpg",
        "/cover_images/Spain/photo2.jpg",
        "/cover_images/Spain/photo3.jpg",
        "/cover_images/Spain/photo4.jpg",
        "/cover_images/Spain/photo5.jpg",
      ],
      default: "7D6N",
      packages: {
        "7D6N": {
          display_text: "7 Days - 6 Nights",
          cover: "Barcelona | Valencia | Madrid ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,72,250/-",
          display_pricing: "BDT 1,72,250/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Barcelona- Hotel Arc La Rambla or similar",
            "Nights accommodation in Valencia - Hotel Petit Palace Ruzafa or similar",
            "Nights accommodation in Madrid- Hotel Occidental Castellana Norte or similar",
            "Daily Breakfast",
            "Barcelona City Tour By Bus 24 Hours Hop-On Hop-Off",
            "Sagrada Familia Skip-The-Line Guided Tour",
            "Madrid City Tour By Bus 24 Hours Hop-On Hop-Off",
            "Royal Palace Madrid Afternoon Guided Tour in a Small Group",
            "2nd Class Train from Valencia - Madrid",
            "Private Transfer from Barcelona Airport to Hotel",
            "Private Transfer from Valencia Hotel to Station",
            "Private Transfer from Madrid Station to Hotel",
            "Private Transfer from Madrid Hotel to Airport",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 34,
      name: "Explore Spain",
      cover_image: "/cover_images/Explore Spain/exspain_cover_image.jpg",
      slider_images: [
        "/cover_images/Explore Spain/photo1.jpg",
        "/cover_images/Explore Spain/photo2.jpg",
        "/cover_images/Explore Spain/photo3.jpg",
        "/cover_images/Explore Spain/photo4.jpg",
        "/cover_images/Explore Spain/photo5.jpg",
      ],
      default: "9D8N",
      packages: {
        "9D8N": {
          display_text: "9 Days - 8 Nights",
          cover: "Barcelona | Valencia | Madrid | Ibiza Catamaran Cruise | Royal Palace Madrid ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 2,21,000/-",
          display_pricing: "BDT 2,21,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Barcelona- Hotel Arc La Rambla or similar",
            "Nights accommodation in Valencia - Hotel Petit Palace Ruzafa or similar",
            "Nights accommodation in Ibiza - Hotel Simbad or similar",
            "Nights accommodation in Madrid- Hotel Occidental Castellana Norte or similar",
            "Daily Breakfast",
            "Barcelona City Tour By Bus 24 Hours Hop-On Hop-Off",
            "Sagrada Familia Skip-The-Line Guided Tour",
            "Valencia City Tour By Bus 24 Hours Hop-On Hop- Off",
            "Ibiza Catamaran Cruise",
            "Madrid City Tour By Bus 24 Hours Hop-On Hop-Off",
            "Royal Palace Madrid Afternoon Guided Tour in a Small Group",
            "Economy Coach Ticket Barcelona-Valencia",
            "2nd Class Train from Valencia - Madrid",
            "Private Transfer from Barcelona Airport to Hotel",
            "Private Transfer from Valencia Hotel to Airport",
            "Private Transfer from Ibiza Airport to Hote",
            "Private Transfer from Ibiza Hotel to Airport",
            "Private Transfer from Madrid Airport to Hotel",
            "Private Transfer from Madrid Hotel to Airport",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 35,
      name: "United Kingdom",
      cover_image: "/cover_images/United Kingdom/uk_cover_image.jpg",
      slider_images: [
        "/cover_images/United Kingdom/photo1.jpg",
        "/cover_images/United Kingdom/photo2.jpg",
        "/cover_images/United Kingdom/photo3.jpg",
        "/cover_images/United Kingdom/photo4.jpg",
        "/cover_images/United Kingdom/photo5.jpg",
      ],
      default: "8D7N",
      packages: {
        "8D7N": {
          display_text: "8 Days - 7 Nights",
          cover: "London | Windermere | Manchester | London 24 Hours Hop On Hop Off Bus Tour | Madame Tussauds Entrance Ticket | London Eye Ticket | Lake District Beatrix Potter Half Day Tour from Windermere | Manchester 24 Hours Hop On Hop Off Bus Tour | Private Transfer from London Hotel to Station",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 2,60,000/-",
          display_pricing: "BDT 2,60,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in London Hotel Corus Hyde Park or similar",
            "Nights accommodation in Windermere - Hotel Windermere or similar",
            "Nights accommodation in Manchester - Hotel Brittania or similar",
            "Night accommodation in London Hotel Corus Hyde Park or similar",
            "Daily Breakfast",
            "2nd Class Train from London to Windermere",
            "2nd Class Train from Windermere to Manchester",
            "2nd Class Train from Manchester to London",
            "London 24 Hours Hop On Hop Off Bus Tour",
            "Madame Tussauds Entrance Ticket",
            "London Eye Ticket",
            "Lake District Beatrix Potter Half Day Tour from Windermere",
            "Manchester 24 Hours Hop On Hop Off Bus Tour",
            "Private Transfer from London Hotel to Station",
            "Private Transfer from Manchester Station to Hotel",
            "Private Transfer from Manchester Hotel to Station",
            "Private Return Airport Transfers in London",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 36,
      name: "Uk & Scotland",
      cover_image: "/cover_images/Uk & Scotland/Uk&Scotland_cover_Image.jpg",
      slider_images: [
        "/cover_images/Uk & Scotland/photo1.jpg",
        "/cover_images/Uk & Scotland/photo2.jpg",
        "/cover_images/Uk & Scotland/photo3.jpg",
        "/cover_images/Uk & Scotland/photo4.jpg",
        "/cover_images/Uk & Scotland/photo5.jpg",
      ],
      default: "14D13N",
      packages: {
        "14D13N": {
          display_text: "14 Days - 13 Nights",
          cover: "London | Edinburgh | Inverness | Glasgow | Madame Tussauds | London Eye | Edinburgh Castle Tickets | Loch Ness Sightseeing Cruise | Stirling Castle Tickets | London 48 Hours Hop On Hop Off Tour | 2nd Class Trains",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 4,68,000/-",
          display_pricing: "BDT 4,68,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights Accommodation in London - Mercure London Paddington Hotel or similar",
            "Nights Accommodation in Edinburgh - Holiday Inn Express Edinburgh Royal Mile or similar",
            "Nights Accommodation in Inverness - Pentahotel Inverness or similar",
            "Nights Accommodation in Glasgow - Novotel Glasgow Centre or similar",
            "Night Accommodation in London - Mercure London Paddington Hotel or similar",
            "2nd Class Train from London to Edinburgh",
            "2nd Class Train from Edinburgh to Inverness",
            "2nd Class Train from Inverness to Glasgow",
            "2nd Class Train from Glasgow to London",
            "London 48 Hrs. Hop-On Hop-Off Tour and 30 Min River Cruise",
            "Madame Tussauds and London Eye",
            "Edinburgh Hop-on Hop-off City Tour 24 Hours Bus",
            "Lake District Beatrix Potter Half Day Tour from Windermere",
            "Edinburgh Castle Tickets",
            "Loch Ness Sightseeing Cruise in Inverness",
            "Stirling Castle, Loch Lomond and Whisky Trail Small Group Day Tour from Glasgow",
            "Return Private Airport Transfers in London",
            "Private Transfer from London Hotel to Station",
            "Private Transfer from Edinburgh Station to Hotel",
            "Private Transfer from Edinburgh Hotel to Station",
            "Private Transfer from Inverness Station to Hotel",
            "Private Transfer from Inverness Hotel to Station",
            "Private Transfer from Glasgow Station to Hotel",
            "Private Transfer from Glasgow Hotel to Station",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 37,
      name: "Portugal",
      cover_image: "/cover_images/Portugal/Portugal_cover_image.jpg",
      slider_images: [
        "/cover_images/Portugal/photo1.jpg",
        "/cover_images/Portugal/photo2.jpg",
        "/cover_images/Portugal/photo3.jpg",
        "/cover_images/Portugal/photo4.jpg",
        "/cover_images/Portugal/photo5.jpg",
      ],
      default: "7D6N",
      packages: {
        "7D6N": {
          display_text: "7 Days - 6 Nights",
          cover: "Lisbon | Porto | Sintra & Cascais",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,62,500/-",
          display_pricing: "BDT 1,62,500/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights Accommodation in Lisbon Hotel Eurostars Lisboa Parque or similar",
            "Nights Accommodation in Porto Hotel Portus Cale or similar",
            "Night Accommodation in Lisbon - Hotel",
            "Eurostars Lisboa Parque or similar",
            "Daily Breakfast",
            "Coach Ticket from Lisbon to Porto",
            "Coach Ticket from Porto to Lisbon",
            "Lisbon City Tour By Bus 24 Hours Hop On Hop Off",
            "Sintra & Cascais Day Tour from Lisbon",
            "2nd Class Train from Valencia - Madrid",
            "Private Transfer from Barcelona Airport to Hotel",
            "Porto: Douro River Panoramic Tour by Boat",
            "Private Return Transfers from Lisbon Airport to Hotel to Airport",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 38,
      name: "Portugal & Spain",
      cover_image: "/cover_images/Portugal & Spain/Portugal_Spain_cover_Image.jpg",
      slider_images: [
        "/cover_images/Portugal & Spain/photo1.jpg",
        "/cover_images/Portugal & Spain/photo2.jpg",
        "/cover_images/Portugal & Spain/photo3.jpg",
        "/cover_images/Portugal & Spain/photo4.jpg",
        "/cover_images/Portugal & Spain/photo5.jpg",
      ],
      default: "7D6N",
      packages: {
        "7D6N": {
          display_text: "7 Days - 6 Nights",
          cover: "Pahalgam | Gulmarg | Sonmarg | Srinagar",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,62,500/-",
          display_pricing: "BDT 1,62,500/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights Accommodation in Barcelona- Hotel Arc La Rambla or similar",
            "Nights Accommodation in Valencia - Hotel Petit Palace Ruzafa or similar",
            "Nights Accommodation in Madrid- Hotel Occidental Castellana Norte or similar",
            "Nights Accommodation in Lisbon - Hotel Eurostars Lisbon Parque or similar",
            "Barcelona City Tour By Bus 24 Hours Hop-On Hop-Off",
            "Sagrada Familia Skip-The-Line Guided Tour Madrid City Tour By Bus 24 Hours Hop-On Hop-Off",
            "Royal Palace Madrid Afternoon Guided Tour in a Small Group",
            "Valencia Open Bus 24 Hours Hop On Hop Off",
            "Economy Coach Ticket Barcelona-Valencia",
            "2nd Class Train from Valencia - Madrid",
            "2nd Class Train from Madrid - Lisbon",
            "Rivate Airport Transfer from Barcelona Airport to Hotel",
            "Lisbon City Tour By Bus 24 Hours Hop On Hop Off",
            "Sintra & Cascais Day Tour from Lisbon",
            "Private Transfer in Valencia Hotel to Station",
            "Private Transfer in Madrid Station to Hotel",
            "Private Transfer Madrid Hotel to Station",
            "Private Transfer from Lisbon Station to Hotel",
            "Private Departure Transfer from Lisbon Airport",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 39,
      name: "Ireland",
      cover_image: "/cover_images/Ireland/Ireland_cover_image.jpg",
      slider_images: [
        "/cover_images/Ireland/photo1.jpg",
        "/cover_images/Ireland/photo2.jpg",
        "/cover_images/Ireland/photo3.jpg",
        "/cover_images/Ireland/photo4.jpg",
        "/cover_images/Ireland/photo5.jpg",
      ],
      default: "7D6N",
      packages: {
        "7D6N": {
          display_text: "7 Days - 6 Nights",
          cover: "Belfast City  | Galway  | Dublin - Hampton  | Dublin City Centre ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 3,17,200/-",
          display_pricing: "BDT 3,17,200/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Night accommodation in Belfast Holiday Inn Belfast City Centre or similar",
            "Night accommodation in Galway - Nox Hotel or similar",
            "Night accommodation in Dublin = Hampton by Hilton Dublin City Centre or similar",
            "Daily Breakfast",
            "Belfast City Tour with Giant's Causeway Tour (With Hotel Pickup SIC)",
            "Day Trip to Cliffs of Moher (With Hotel Pickup SIC)",
            "2nd Class Train ticket from Galway - Dublin",
            "Dublin Hop On Hop Off Bus Tour - 24 Hours",
            "Private arrival transfer in Belfast",
            "Private departure transfer in Belfast",
            "Private transfer from Belfast to Galway",
            "Private departure transfer in Galway",
            "Private arrival & departure transfer in Dublin",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 40,
      name: "Scandinavia",
      cover_image: "/cover_images/Scandinavia/Scandinavia_cover_image.jpg",
      slider_images: [
        "/cover_images/Scandinavia/photo1.jpg",
        "/cover_images/Scandinavia/photo2.jpg",
        "/cover_images/Scandinavia/photo3.jpg",
        "/cover_images/Scandinavia/photo4.jpg",
        "/cover_images/Scandinavia/photo5.jpg",
      ],
      default: "10D9N",
      packages: {
        "10D9N": {
          display_text: "10 Days - 9 Nights",
          cover: "Oslo | Stockholm | Copenhagen | Kon-Tiki Museum | Stockholm Old Town Walking | Copenhagen | Tivoli Gardens Entrance",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 3,14,600/-",
          display_pricing: "BDT 3,14,600/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights stay in Oslo - Scandic Victoria or similar",
            "Nights stay in Stockholm Hotel Scandic Anglais or similar",
            "Nights stay in Copenhagen - Hotel Scandic Sluseholmen or similar",
            "Daily Breakfast",
            "Panoramic Oslo Coach Tour 2 Hours",
            "Kon-Tiki Museum Entrance Ticket in Oslo",
            "Stockholm Old Town Walking Tour",
            "Vasa Ship Museum Tickets in Stockholm",
            "Copenhagen Walking Tour",
            "Tivoli Gardens Entrance",
            "Return Airport Transfers in Oslo Private",
            "Return Airport Transfers in Stockholm Private",
            "Return Airport Transfers in Copenhagen Private",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 41,
      name: "Finland",
      cover_image: "/cover_images/Finland/finland_cover_image.jpg",
      slider_images: [
        "/cover_images/Finland/photo1.jpg",
        "/cover_images/Finland/photo2.jpg",
        "/cover_images/Finland/photo3.jpg",
        "/cover_images/Finland/photo4.jpg",
        "/cover_images/Finland/photo5.jpg",
      ],
      default: "10D9N",
      packages: {
        "10D9N": {
          display_text: "7 Days - 6 Nights",
          cover: "Helsinki | Rovaniemi | Kemi | Porvoo Tour | Husky Village | Reindeer Farm and Santa Village | Coach Basis | ",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 3,70,500/-",
          display_pricing: "BDT 3,70,500/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Helsinki - Holiday Inn Helsinki City Centre or similar",
            "Nights accommodation in Rovaniemi- Arctic Snow Hotel & Glass Igloos or similar",
            "Nights accommodation in Kemi - Scandic Kemi or similar",
            "Night accommodation in Helsinki - Holiday Inn Helsinki City Centre or similar",
            "Daily Breakfast",
            "Private Transfer from Helsinki Airport to Hotel to Airport",
            "Private Transfer from Rovaniemi Airport to Hotel",
            "Private Transfer from Kemi Hotel to Kemi Airport",
            "Private Transfer from Helsinki Airport to Hotel to Airport",
            "Train 2nd Class from Helsinki to Rovaniemi",
            "City Sightseeing Helsinki Hop-On Hop-Off Bus Tour",
            "Porvoo Tour from Helsinki on Seat In Coach Basis",
            "Sampo Ice Breaker Cruise from Kemi on Seat In Coach Basis",
            "Husky, Reindeer Farm and Santa Village",
            "Experience From Rovaniemi on Seat In",
            "Coach Basis",
            "Northern Lights Tour by Snowmobile from Rovaniemi on Seat In Coach Basis",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
    {
      id: 42,
      name: "Croatia",
      cover_image: "/cover_images/Croatia/croatia_cover_image.jpg",
      slider_images: [
        "/cover_images/Croatia/photo1.jpg",
        "/cover_images/Croatia/photo2.jpg",
        "/cover_images/Croatia/photo3.jpg",
        "/cover_images/Croatia/photo4.jpg",
        "/cover_images/Croatia/photo5.jpg",
      ],
      default: "8D7N",
      packages: {
        "8D7N": {
          display_text: "8 Days - 7 Nights",
          cover: "Pahalgam | Gulmarg | Sonmarg | Srinagar",
          include: "Hotel - Transfer-cost - Sightseeing - Taxes - Vehicle",
          pricing: "BDT 1,17,000/-",
          display_pricing: "BDT 1,17,000/-",
          pricing_dec: "For Further Details Contact Us. And the Price may change without any prior notice. The Price is inclusive of all taxes. Per person Package cost with hotel on twin sharing basis, tours and transfer on PVT basis & inclusive of Vat & tickets price. These are only quotation, all fare and price may change without any prior notice. This is Land Package only, Air Ticket & Visa Cost Separate. If there is two country or more than one then visa charge will be changed according to country visa charge.",
          inclusions: [
            "Nights accommodation in Zagreb - Canopy by Hilton Zagreb City Centre or similar",
            "Nights accommodation in Split - Marmontova Luxury Rooms or similar",
            "Nights accommodation in Dubrovnik - Hotel Adria or similar",
            "Daily Breakfast",
            "Coach from Zagreb to Split",
            "Coach from Split to Dubrovnik",
            "Zagreb City Walking Tour",
            "Split Old town Small group Walking tour",
            "Krka National Park Ecco Tour from Split",
            "Exclusive: 'Game of Thrones' Walking Tour of Dubrovnik",
            "Transfer from Zagreb Airport to Hotel",
            "Transfer from Dubrovnik Hotel to Airport",
          ],
          exclusions: [
            "Visa Fee with Service Charge – BDT 1500",
            "Lunch & Dinner.",
            "All Entrance fees.",
            "Tips to driver or guide.",
            "Shopping.",
            "Sim Card."
          ],
          itinery: [
            {
              title: "Day : 1 Arrival in Delhi:",
              itinery_img: "/cover_images/kashmir/kashmir_1.jpg",
              description: "Depart from Dhaka, Arrival in Delhi, Transfer to hotel near Delhi Airport,Night Stay in Delhi"
            },
            {
              title: "Day : 2 Srinagar to Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_2.jpg",
              description: "Flight to Srinagar. After arrival Srinagar, Transfer to Pahalgam by Private car. Stay in Pahalgam."
            },
            {
              title: "Day : 3 Pahalgam:",
              itinery_img: "/cover_images/kashmir/kashmir_3.jpg",
              description: "Full Day tour in Pahalgam, Stay in Pahalgam."
            },
            {
              title: "Day : 4 Pahalgam to Gulmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_4.jpg",
              description: "Depart from Pahalgam, Drive to Gulmarg, Arrival Gulmarg, Tour in Gulmarg. Stay in Gulmarg."
            },
            {
              title: "Day : 5 Gulmarg to Sonmarg:",
              itinery_img: "/cover_images/kashmir/kashmir_5.jpg",
              description: "Depart from Gulmarg, Drive to Sonmarg, Arrival Sonmarg, Tour in Sonmarg. Stay in Sonmarg."
            },
            {
              title: "Day : 6 Sonmarg to Srinagar:",
              itinery_img: "/cover_images/kashmir/kashmir_6.jpg",
              description: "Depart from Sonmarg, Drive to Srinagar, Arrival in Srinagar, Tour in Srinagar, Stay in Srinagar."
            },
            {
              title: "Day : 7 Srinagar to Delhi",
              itinery_img: "/cover_images/kashmir/kashmir_7.jpg",
              description: "Depart from Srinagar for Dhaka via Delhi."
            },
          ]
        }
      }
    },
  ]
}


