import React from 'react';
import { Link } from 'react-router-dom';
import { data } from "../data";
import Header from '../Header';
import Footer from '../home/Footer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { lime } from '@mui/material/colors';


// function shuffleArray(array) {
//     // Using Fisher-Yates shuffle algorithm
//     for (let i = array.length - 1; i > 0; i--) {
//         const j = Math.floor(Math.random() * (i + 1));
//         [array[i], array[j]] = [array[j], array[i]];
//     }
//     return array;
// }


function TourPakages() {

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    const [packageList, setPackageList] = React.useState([]);

    React.useEffect(() => {
        setPackageList(data.cardData);
        //  setPackageList(shuffleArray(data.cardData));
    }, [setPackageList]);


    const [search, setSearch] = React.useState('');
    const selectHandler = (e) => {
        setSearch(e.target.value);
    }


    return (
        <>
            <Header />
            <div className='m-3' >
                <div className='text-center z-10 flex justify-center font-mono font-bold text-3xl'>
                    <h1 className='border-b border-b-lime-500 w-96 m-10 z-40 text-center'>Tour Packages</h1>
                </div>
                <div className=''>
                    <div className='flex items-center justify-center border-b pb-5'>
                        <form className='flex space-x-2 w-[100%] justify-center' onChange={selectHandler}>
                            <input
                                id="combo-box-demo"             
                                type="text" placeholder='Search Packages...' className='border border-lime-600 rounded-lg text-xl active:border-lime-600 focus:border-lime-600 font-mono px-2 py-2 w-[80%] md:w-[60%]' />
                        </form>
                    </div>

                </div>
                <div className='flex-col m-5 justify-between space-x-5'>
                    <div className='flex-col flex'>
                        <div className="p-5" >
                            <div className='grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 grid-col-2 gap-10 ' >
                                {packageList && packageList.filter((item) => {
                                    return search.toLowerCase() === ''
                                        ? item
                                        : item.name.toLowerCase().includes(search.toLowerCase())
                                }).map((each, index) => {
                                    return (
                                        <div key={each.id}>
                                            <Link to={`/package/${each.id}`} key={index} className='w-[50%]'>
                                                <div className="max-w-sm hover:scale-105 h-[28rem] transition-all duration-300 cursor-pointer bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg shadow-lime-100" value="card">
                                                    <div className='flex justify-center'>
                                                        <img className="p-2 border-4 border-white w-full h-56 object-fill" src={each.cover_image} alt="thumbnail" />
                                                    </div>
                                                        
                                                    <div className="p-5 text-center mt-auto">
                                                        <button>
                                                            <h5 className="mb-2 font-serif text-xl font-bold w-full text-black ">{each.name}</h5>
                                                        </button>

                                                        <p className="mb-3 font-bold text-lg text-gray-700  dark:text-gray-400">{each.desc}</p>
                                                        <div className='flex justify-center space-x-2'>
                                                            <AccessTimeIcon sx={{ color: lime[800] }} />
                                                            <span>{each.packages[each.default].display_text}</span>
                                                        </div>
                                                        <div className='flex mt-2 gap-2 justify-center' >
                                                            <p className="mb-3 text-xl  font-bold text-lime-900 dark:text-gray-400">{each.packages[each.default].pricing}</p>
                                                            <p className='text-xs'>per person.</p>
                                                        </div>

                                                        <button className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-lime-600 rounded-lg hover:bg-lime-800 focus:ring-4 focus:outline-none focus:ring-lime-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                                            View Package
                                                            <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* footer */}
            <Footer />
        </>
    )
}

export default TourPakages