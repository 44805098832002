import React from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function Calender() {
  const [value, onChange] = React.useState(new Date());

  return (
    <div>
        <Calendar onChange={onChange} className="w-fit text-center " value={value} />
    </div>
  )
}

export default Calender